// Components
import { trads } from "src/data/trads";
import { GetTrad } from "../Partials/GetTrad";
// Style
import "./splashscreen.css";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/reducers";
import { setLanguage } from "src/actions/app";
import { useEffect, useState } from "react";

export const Splashscreen = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);
	const dispatch = useDispatch();

	const [langSelection, setLangSelection] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			let splashscreenPopupElem = document.getElementById("splashscreenPopup");

			if (splashscreenPopupElem) {
				if (langSelection) {
					splashscreenPopupElem.style.transform = "translateY(0%)";
				} else {
					splashscreenPopupElem.style.transform = "translateY(200%)";
				}
			}
		}, 10);
	}, [langSelection]);

	useEffect(() => {
		let splashscreenstartlangSelectFlagElem = document.getElementById('splashscreen-start-langSelectFlag');

		if (splashscreenstartlangSelectFlagElem) {
			console.log("PASSAGE")
			splashscreenstartlangSelectFlagElem.style.backgroundImage = trads.find((t) => t.id === language)?.flag as any
		}
	}, [language])

	return (
		<div
			className='splashscreen'
			onClick={(e) => {
				e.stopPropagation();
				setLangSelection(false);
			}}
		>
			<div className='splashscreen-content'>
				<h1 className='splashscreen-title'>
					<span className='splashscreen-title-line1'>
						<GetTrad lang={language} value='mainTitleLine1' />
					</span>
					<br />
					<span className='splashscreen-title-line2'>
						<GetTrad lang={language} value='mainTitleLine2' />
					</span>
				</h1>
				<h2 className='splashscreen-subtitle'>
					<GetTrad lang={language} value='mainSubtitle' />
				</h2>
			</div>
			<div className='splashscreen-start'>
				<div
					className='splashscreen-start-button'
					onClick={(e) => {
						e.stopPropagation();
						if (!langSelection) props.next();
					}}
				>
					<div className='splashscreen-start-buttonText'>
						<GetTrad lang={language} value='start' />
						<div className='splashscreen-start-buttonArrow'></div>
					</div>
				</div>
				<div
					className='splashscreen-start-langSelect'
					onClick={(e) => {
						e.stopPropagation();
						if (!langSelection) setLangSelection(true);
					}}
				>
					<div className='splashscreen-start-langSelectText'>
						<div id='splashscreen-start-langSelectFlag' className='splashscreen-start-langSelectFlag'></div>
						<GetTrad lang={language} value={"lang"} />
						<div className='splashscreen-start-langSelectArrow'></div>
					</div>
				</div>
				{langSelection && (
					<div
						id='splashscreenPopup'
						className='splashscreenPopup'
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						{trads.map((t: any) => {
							return (
								<div
									className={
										"splashscreenPopupButton " +
										(t.id === language ? "splashscreenPopupButtonSelected" : "")
									}
									onClick={(e) => {
										e.stopPropagation();
										dispatch(setLanguage(t.id));
										setLangSelection(false);
									}}
								>
									<div
										className={
											"splashscreenPopupButtonText " +
											(t.id === language ? "splashscreenPopupButtonTextSelected" : "")
										}
									>
										<div
											className='splashscreen-start-langSelectFlag'
											style={{ backgroundImage: t.flag }}
										></div>
										<GetTrad lang={t.id} value={"lang"} />
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};
