// Style
import "./privatePolicy.css";
import { GetTrad } from "../Partials/GetTrad";
import { privatePolicy } from "src/data/privatePolicy";
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
import React from "react";
import { useCookies } from "react-cookie";

export const underlinize = (value: string, tradOrigin: string, i: number) => {
	if (!value || value === "") {
		return <React.Fragment></React.Fragment>;
	}

	let tradParsed = value.split("%u");

	return (
		<React.Fragment>
			{tradParsed.map((t: any, index: number) => {
				if (index % 2 === 0) {
					// Just string
					return <span key={"t-" + tradOrigin + i + "-" + index}>{t}</span>;
				} else {
					// Link
					return (
						<span
							key={"t-" + tradOrigin + i + "-" + index}
							style={{ textDecoration: "underline" }}
						>
							{t}
						</span>
					);
				}
			})}
		</React.Fragment>
	);
};

export const PrivatePolicy = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);

	const [cookies, setCookie] = useCookies(["cookiesAllowed"]);
	
	return (
		<div className='privatePolicy'>
			<div
				className='ui-top-button ui-top-button-back'
				style={{ position: "absolute", zIndex: "300" }}
				onClick={() => props.displayPrivatePolicyPage(false)}
			></div>

			<div className='privatePolicy-content'>
				<div className='privatePolicy-title'>
					<GetTrad lang={language} value='privatePolicyTitle' />
				</div>
				<div className='privatePolicy-body'>
					{privatePolicy
						.find((pp: any) => pp.id === language)
						?.datas?.map((d: any, index: number) => {
							switch (d.type) {
								case "bold":
									return (
										<div
											key={"privatePolicy-" + index}
											className='privatePolicy-bold'
										>
											{underlinize(d.value, d.value, index)}
										</div>
									);
								case "regular":
									return (
										<div
											key={"privatePolicy-" + index}
											className='privatePolicy-regular'
										>
											{underlinize(d.value, d.value, index)}
										</div>
									);
								default:
									return <></>;
							}
						})}
					<div className='cookies-buttons' style={{width: "100%", margin: "32px 0 64px 0"}}>
						<div
							className='cookies-buttonPolicy cookies-buttonPolicy-yes'
							onClick={() => {
								console.log("setCookiePopup à false");
								props.setCookiePopup(false);
								props.displayPrivatePolicyPage(false)
							}}
						>
							<div className='cookies-buttonText'>
								<GetTrad lang={language} value='cookiesDenied' />
							</div>
						</div>
						<div
							className='cookies-buttonPolicy cookies-buttonPolicy-no'
							onClick={() => {
								setCookie("cookiesAllowed", true);
								props.displayPrivatePolicyPage(false)
								props.setCookiePopup(false);
							}}
						>
							<div className='cookies-buttonText'>
								<GetTrad lang={language} value='cookiesAccept' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
