// React
import { useEffect, useRef, useState } from "react";
import { unstable_HistoryRouter as HistoryRouter, useRoutes, RouteObject } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { useCookies } from "react-cookie";
// Components
import { Map } from "./components/Map/Map";
import { Splashscreen } from "./components/Splashscreen/Splashscreen";
import { Ui } from "./components/Map/Ui";
import { SecretCode } from "./components/SecretCode/SecretCode";
import { Poi } from "./components/Poi/Poi";
import { Glossary } from "./components/Glossary/Glossary";
import { PrivatePolicy } from "./components/PrivatePolicy/PrivatePolicy";
import { Cookies } from "./components/Partials/Cookies/Cookies";
// Style
import "./App.css";
// Assets
import UI_TabBar_top from "./style/devAssets/UI_TabBar_top.jpg";
import UI_TabBar_bottom from "./style/devAssets/UI_TabBar_bottom.jpg";
// Types
import { POI_STATE } from "./types/poi_state";

import { resetProgress, setGlossaryProgress, setPoisProgress } from "./actions/game";
import { Timeline } from "./components/Timeline/Timeline";
import { IRootState } from "./reducers";
import { Credits } from "./components/Credits/Credits";
import { GetTrad } from "./components/Partials/GetTrad";
import { EndExp } from "./components/EndExp/EndExp";

import linkButton from "./style/assets/linkButton.svg";
import xButton from "./style/assets/twitterButton.svg";
import whatsappButton from "./style/assets/whatsappButton.svg";

let history = require("history").createBrowserHistory();

const AppRoutes = (props: {
	dev: boolean;
	page: string;
	setPage: any;
	poi: string | null;
	newPoi: string | null;
	setPoi: any;
	displayPoiState: POI_STATE;
	setDisplayPoiState: any;

	creditsPage: any;
	displayCreditsPage: any;
	privatePolicyPage: any;
	displayPrivatePolicyPage: any;
}) => {
	const dispatch: Dispatch<any> = useDispatch();
	const { language } = useSelector((state: IRootState) => state.app);

	const { pois, glossary } = useSelector((state: IRootState) => state.game);

	/* Cookies */
	const [cookies, setCookie] = useCookies(["cookiesAllowed", "pois", "glossary"]);
	const [cookiePopup, setCookiePopup] = useState(true);
	const [first, setFirst] = useState<boolean>(true);
	const [firstMap, setFirstMap] = useState<boolean>(true);
	const [firstEnd, setFirstEnd] = useState<boolean>(true);

	useEffect(() => {
		if (cookies.cookiesAllowed) {
			if (first) {
				if (cookies.pois) {
					console.log("cookies.poi exists");
					dispatch(setPoisProgress(cookies.pois));
				}
				if (cookies.glossary) {
					console.log("cookies.glossary exists");
					dispatch(setGlossaryProgress(cookies.glossary));
				}
				setFirst(false);
			}
		}
	}, [dispatch, cookies]);

	useEffect(() => {
		if (!first) {
			if (cookies.cookiesAllowed) {
				let poisToStore = [];
				for (let poi of pois) {
					poisToStore.push({
						id: poi.id,
						found: poi.found,
					});
				}
				setCookie("pois", JSON.stringify(poisToStore));
			}
		}
	}, [pois]);

	useEffect(() => {
		if (!first) {
			if (cookies.cookiesAllowed) {
				let glossariesToStore = [];
				for (let g of glossary) {
					glossariesToStore.push({
						id: g.id,
						found: g.found,
					});
				}
				setCookie("glossary", JSON.stringify(glossariesToStore));
			}
		}
	}, [glossary]);

	const [sharePopup, setSharePopup] = useState<string | null>(null);
	const _sharePopup = useRef(sharePopup);

	const [glossaryPopup, setGlossaryPopup] = useState<string | null>(null);
	const _glossaryPopup = useRef(glossaryPopup);

	useEffect(() => {
		if (glossaryPopup) {
			_glossaryPopup.current = glossaryPopup;
			let elems = document.getElementsByClassName("glossaryPopup");

			for (let elem of elems) {
				(elem as any).style.opacity = "1";
				(elem as any).style.transform = "translateY(0%)";
			}
		} else {
			let elems = document.getElementsByClassName("glossaryPopup");

			for (let elem of elems) {
				(elem as any).style.transform = "translateY(150%)";
			}

			setTimeout(() => {
				for (let elem of elems) {
					(elem as any).style.opacity = "0";
				}
				_glossaryPopup.current = glossaryPopup;
			}, 1000);
		}
	}, [glossaryPopup]);

	useEffect(() => {
		if (sharePopup) {
			_sharePopup.current = sharePopup;
			let elems = document.getElementsByClassName("sharePopup");

			for (let elem of elems) {
				(elem as any).style.opacity = "1";
				(elem as any).style.transform = "translateY(0%)";
			}
		} else {
			let elems = document.getElementsByClassName("sharePopup");

			for (let elem of elems) {
				(elem as any).style.transform = "translateY(150%)";
			}

			setTimeout(() => {
				for (let elem of elems) {
					(elem as any).style.opacity = "0";
				}
				_sharePopup.current = sharePopup;
			}, 1000);
		}
	}, [sharePopup]);

	let element = (
		<div className={"app-container"}>
			{props.dev && <div className='dev-ui-top' style={{ backgroundImage: `url(${UI_TabBar_top})` }}></div>}
			{glossaryPopup && (
				<div
					className='glossaryPopupBackground'
					onClick={() => {
						setGlossaryPopup(null);
					}}
				></div>
			)}
			<div className='glossaryPopup'>
				<div className='glossaryPopupTitle'>
					<GetTrad
						lang={language}
						value={
							glossary.find((g: any) => g.id === glossaryPopup || g.id === _glossaryPopup.current)?.name
						}
					/>
				</div>
				<div className='glossaryPopupDesc'>
					<GetTrad
						lang={language}
						value={
							glossary.find((g: any) => g.id === glossaryPopup || g.id === _glossaryPopup.current)?.desc
						}
					/>
				</div>
				<div className='glossaryPopupUnlock'>
					<GetTrad lang={language} value='glossaryUnlock' />
				</div>
				<div
					className='glossaryPopupButton'
					onClick={() => {
						props.setPoi(null);
						props.setDisplayPoiState(POI_STATE.NONE);
						props.setPage("glossary");
						setGlossaryPopup(null);
					}}
				>
					<div className='glossaryPopupButtonText'>
						<GetTrad lang={language} value='goGlossary' />
						<div className='glossaryPopupButtonArrow'></div>
					</div>
				</div>
			</div>
			<div className='sharePopup' style={{ position: "absolute", zIndex: "32000", pointerEvents: "all" }}>
				<div className='sharePopupTitle'>
					<GetTrad lang={language} value={"shareExpWithFriends"} />
				</div>
				<div className='sharePopupDesc'>
					<div className='sharePopupSocial' onClick={() => alert("TODO")}>
						<div className='sharePopupSocial-img' style={{ backgroundImage: `url(${linkButton})` }}></div>
						<div
							className='secretCode-congrats-desc'
							style={{
								width: "90%",
								margin: "0 0 8px 0",
								color: "#765C4E",
								fontFamily: "Geist",
								fontSize: "14px",
								fontWeight: 400,
								lineHeight: "20px",
								letterSpacing: "0.28px",
								textAlign: "center",
							}}
						>
							<GetTrad lang={language} value={"link"} />
						</div>
					</div>
					<div className='sharePopupSocial' onClick={() => alert("TODO")}>
						<div className='sharePopupSocial-img' style={{ backgroundImage: `url(${xButton})` }}></div>
						<div
							className='secretCode-congrats-desc'
							style={{
								width: "90%",
								margin: "0 0 8px 0",
								color: "#765C4E",
								fontFamily: "Geist",
								fontSize: "14px",
								fontWeight: 400,
								lineHeight: "20px",
								letterSpacing: "0.28px",
								textAlign: "center",
							}}
						>
							<GetTrad lang={language} value={"x"} />
						</div>
					</div>
					<div className='sharePopupSocial' onClick={() => alert("TODO")}>
						<div
							className='sharePopupSocial-img'
							style={{ backgroundImage: `url(${whatsappButton})` }}
						></div>
						<div
							className='secretCode-congrats-desc'
							style={{
								width: "90%",
								margin: "0 0 8px 0",
								color: "#765C4E",
								fontFamily: "Geist",
								fontSize: "14px",
								fontWeight: 400,
								lineHeight: "20px",
								letterSpacing: "0.28px",
								textAlign: "center",
							}}
						>
							<GetTrad lang={language} value={"whatsapp"} />
						</div>
					</div>
				</div>
			</div>
			{sharePopup && (
				<div
					className='sharePopupBackground'
					onClick={() => {
						setSharePopup(null);
					}}
				></div>
			)}
			<div className='app-content'>
				{/* Subpages */}

				{props.page === "splashscreen" && (
					<Splashscreen
						next={() => {
							props.setPage("go");
						}} /*askAuthorization={askAuthorization}*/
					/>
				)}
				{["go", "timeline", "glossary"].includes(props.page) && (
					<Ui page={props.page} setPage={props.setPage} displayCreditsPage={props.displayCreditsPage} />
				)}
				{props.page === "secretCode" && (
					<SecretCode
						poi={props.poi}
						setPoi={props.setPoi}
						setPage={props.setPage}
						setDisplayPoiState={props.setDisplayPoiState}
					/>
				)}
				{props.page === "secretCode" && props.displayPoiState !== POI_STATE.NONE && (
					<Poi
						poi={props.poi}
						setPoi={props.setPoi}
						setPage={props.setPage}
						displayPoiState={props.displayPoiState}
						setDisplayPoiState={props.setDisplayPoiState}
						setGlossaryPopup={setGlossaryPopup}
					/>
				)}

				{props.page === "poi" && (
					<Poi
						auto={true}
						poi={props.poi}
						setPoi={props.setPoi}
						setPage={props.setPage}
						displayPoiState={props.displayPoiState}
						setDisplayPoiState={props.setDisplayPoiState}
						setGlossaryPopup={setGlossaryPopup}
					/>
				)}

				{props.page === "glossary" && <Glossary setPage={props.setPage} setPoi={props.setPoi} />}
				{props.page === "timeline" && <Timeline />}
			</div>
			{props.dev && <div className='dev-ui-bottom' style={{ backgroundImage: `url(${UI_TabBar_bottom})` }}></div>}
			{["go", "poi"].includes(props.page) && (
				<Map
					firstMap={firstMap}
					setFirstMap={setFirstMap}
					page={props.page}
					setPage={props.setPage}
					poi={props.newPoi}
					setPoi={props.setPoi}
					setDisplayPoiState={props.setDisplayPoiState}
				/>
			)}
			{["go"].includes(props.page) &&
				!props.creditsPage &&
				pois.filter((p: any) => p.clickable !== "false" && p.found === true).length >= 6 &&
				firstEnd && <EndExp language={language} setSharePopup={setSharePopup} setFirstEnd={setFirstEnd} />}
			{["go"].includes(props.page) &&
				!props.creditsPage &&
				pois.filter((p: any) => p.clickable !== "false" && p.found === true).length >= 6 &&
				!firstEnd && (
					<div
						className='endExpReload-shareButton'
						onClick={() => {
							dispatch(resetProgress());
							setFirst(true);
							setFirstMap(true);
							setFirstEnd(true);
						}}
					>
						<GetTrad lang={language} value='restartExp' />
					</div>
				)}
			{props.creditsPage && <Credits displayCreditsPage={props.displayCreditsPage} language={language} />}
			{props.privatePolicyPage && (
				<PrivatePolicy
					setCookiePopup={setCookiePopup}
					displayPrivatePolicyPage={props.displayPrivatePolicyPage}
				/>
			)}
			{!cookies.cookiesAllowed && cookiePopup && !props.privatePolicyPage && (
				<Cookies setCookiePopup={setCookiePopup} displayPrivatePolicyPage={props.displayPrivatePolicyPage} />
			)}
		</div>
	);

	const routes: RouteObject[] = [
		{
			path: "/",
			element: element,
			children: [
				{ index: true, element: element },
				{ path: "/:poiSelected", element: element },
				// { path: '/users', element: home }
			],
		},
	];

	return useRoutes(routes);
};

function App() {
	const [dev] = useState<boolean>(false);

	// Page components
	const [page, setPage] = useState("splashscreen");
	const [poi, setPoi] = useState<string | null>(null);
	const [newPoi, setNewPoi] = useState<string | null>(null);
	const [displayPoiState, setDisplayPoiState] = useState<POI_STATE>(POI_STATE.NONE);

	useEffect(() => {
		setTimeout(() => {
			setNewPoi(poi);
		}, 2000);
	}, [poi]);

	useEffect(() => {
		console.log("Page = ", page);
	}, [page]);

	const [privatePolicyPage, displayPrivatePolicyPage] = useState<boolean>(false);
	const [creditsPage, displayCreditsPage] = useState<boolean>(false);

	return (
		<div className='App'>
			<HistoryRouter history={history}>
				<AppRoutes
					dev={dev}
					page={page}
					setPage={setPage}
					poi={poi}
					newPoi={newPoi}
					setPoi={setPoi}
					displayPoiState={displayPoiState}
					setDisplayPoiState={setDisplayPoiState}
					privatePolicyPage={privatePolicyPage}
					displayPrivatePolicyPage={displayPrivatePolicyPage}
					creditsPage={creditsPage}
					displayCreditsPage={displayCreditsPage}
				/>
			</HistoryRouter>
		</div>
	);
}

export default App;
