import { useEffect, useState } from "react";
import { GetTrad } from "../Partials/GetTrad";
import "./slider.css";
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";

export const Slider = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);

	const { poi } = props;

	const [period, setPeriod] = useState(100);
	const [nextPage, setNextPage] = useState(false);

	useEffect(() => {
		if (props.slider === true) {
			let elemAfterTitleTop = document.getElementById("slider-afterTitleTop");
			let elemBeforeTitleTop = document.getElementById("slider-beforeTitleTop");
			let elemAfterButtonsBottom = document.getElementById(
				"slider-afterButtonsBottom"
			);
			let elemBeforeButtonsBottom = document.getElementById(
				"slider-beforeButtonsBottom"
			);

			if (
				elemAfterTitleTop &&
				elemBeforeTitleTop &&
				elemAfterButtonsBottom &&
				elemBeforeButtonsBottom
			) {
				elemAfterTitleTop.style.display = "flex";
				elemAfterButtonsBottom.style.display = "flex";

				elemAfterButtonsBottom.style.opacity = "1";
				elemBeforeButtonsBottom.style.opacity = "0";

				elemAfterTitleTop.style.opacity = "1";
				elemBeforeTitleTop.style.opacity = "0";

				elemBeforeTitleTop.style.display = "none";
				elemBeforeButtonsBottom.style.display = "none";
			}
		}
	}, [props.slider]);

	useEffect(() => {
		let elemAfterTitleTop = document.getElementById("slider-afterTitleTop");
		let elemBeforeTitleTop = document.getElementById("slider-beforeTitleTop");
		let elemAfterButtonsBottom = document.getElementById(
			"slider-afterButtonsBottom"
		);
		let elemBeforeButtonsBottom = document.getElementById(
			"slider-beforeButtonsBottom"
		);

		if (
			elemAfterTitleTop &&
			elemBeforeTitleTop &&
			elemAfterButtonsBottom &&
			elemBeforeButtonsBottom
		) {
			if (period > 50) {
				setTimeout(() => {
					if (
						elemAfterTitleTop &&
						elemBeforeTitleTop &&
						elemAfterButtonsBottom &&
						elemBeforeButtonsBottom
					) {
						elemAfterTitleTop.style.display = "flex";
						elemAfterButtonsBottom.style.display = "flex";

						elemAfterButtonsBottom.style.opacity = "1";
						elemBeforeButtonsBottom.style.opacity = "0";
						setTimeout(() => {
							if (
								elemAfterTitleTop &&
								elemBeforeTitleTop &&
								elemAfterButtonsBottom &&
								elemBeforeButtonsBottom
							) {
								elemAfterTitleTop.style.opacity = "1";
								elemBeforeTitleTop.style.opacity = "0";

								elemBeforeTitleTop.style.display = "none";
								elemBeforeButtonsBottom.style.display = "none";
							}
						}, 800);
					}
				}, 200);
			} else {
				setTimeout(() => {
					if (
						elemAfterTitleTop &&
						elemBeforeTitleTop &&
						elemAfterButtonsBottom &&
						elemBeforeButtonsBottom
					) {
						elemBeforeTitleTop.style.display = "flex";
						elemBeforeButtonsBottom.style.display = "flex";

						elemAfterTitleTop.style.opacity = "0";
						elemBeforeTitleTop.style.opacity = "1";
						elemAfterButtonsBottom.style.opacity = "0";
						elemBeforeButtonsBottom.style.opacity = "1";
						setTimeout(() => {
							if (
								elemAfterTitleTop &&
								elemBeforeTitleTop &&
								elemAfterButtonsBottom &&
								elemBeforeButtonsBottom
							) {
								elemAfterTitleTop.style.display = "none";
								elemAfterButtonsBottom.style.display = "none";
							}
						}, 200);
					}
				}, 200);
			}
		}

		let afterImgElem = document.getElementById("slider-before");
		if (afterImgElem) {
			afterImgElem.style.height = "calc(" + (100 - period) + "%)";
		}

		// let afterWaveImgElem = document.getElementById("sliderWave-after");
		// if (afterWaveImgElem) {
		// 	afterWaveImgElem.style.height = period + "%";
		// }
	}, [period]);

	console.log("IMG=", poi);

	return (
		<div className='slider'>
			<div
				className='slider-after'
				style={{
					overflow: "hidden",
					aspectRatio: poi.slider.now.aspectRatio,
					height: "100%",
					backgroundImage: poi.slider.now.img,
				}}
			>
				<div
					id='slider-before'
					className='slider-before'
					style={{
						height: "0%",
						overflow: "hidden",
						backgroundImage: poi.slider.before.img,
						backgroundPosition: "top",
						backgroundSize: "100% auto",
					}}
				>
					<div
						id='slider-beforeTitleTop'
						className='slider-titleTop'
						style={{
							color: poi.slider.before.color,
						}}
					>
						{poi.slider.before.text}
					</div>
					<div id='slider-beforeButtonsBottom' className='slider-buttonsBottom'>
						<div
							className='slider-buttonsBottomChange'
							onClick={(e) => {
								console.log("test 10");
								e.stopPropagation();
								setPeriod(100);
								setNextPage(true);
							}}
						>
							<div className='slider-buttonsBottomChangeText'>
								<div
									className={
										"splashscreen-start-buttonTime " +
										"splashscreen-start-buttonTimeHour"
									}
								></div>

								<GetTrad lang={language} value={"nowTime"} />
							</div>
						</div>
						<div
							className='slider-buttonsBottomNext'
							onClick={() => props.showSlider(false)}
						>
							<GetTrad lang={language} value={"knowMoreWith"} />
							<GetTrad lang={language} value={poi.title + "Deter"} />
						</div>
					</div>
				</div>
			</div>

			<div
				id='slider-afterTitleTop'
				className='slider-titleTop'
				style={{
					color: poi.slider.now.color,
				}}
			>
				{poi.slider.now.text}
			</div>
			<div id='slider-afterButtonsBottom' className='slider-buttonsBottom'>
				<div
					className='slider-buttonsBottomChange'
					onClick={(e) => {
						e.stopPropagation();
						setPeriod(0);
						setNextPage(true);
					}}
				>
					<div className='slider-buttonsBottomChangeText'>
						<div
							className={
								"splashscreen-start-buttonTime " +
								"splashscreen-start-buttonTimeAntiHour"
							}
						></div>
						<GetTrad lang={language} value={"backTime"} />
					</div>
				</div>
				<div
					className='slider-buttonsBottomNext'
					style={{ opacity: nextPage ? "1" : "0" }}
					onClick={() => props.showSlider(false)}
				>
					<GetTrad lang={language} value={"knowMoreWith"} />
					<GetTrad lang={language} value={poi.title + "Deter"} />
				</div>
			</div>
		</div>
	);
};
