// Components
import { GetTrad } from "../Partials/GetTrad";
// Style
import "./secretCode.css";
// Datas
import { pois } from "../../data/pois";
import { useEffect, useState } from "react";
// Types
import { POI_STATE } from "src/types/poi_state";
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";

export const SecretCode = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);
	
	const [poi] = useState(pois.find((p: any) => p.id === props.poi));

	const [successScreen, setSuccessScreen] = useState<boolean | null>(null);
	const [congrats, setCongrats] = useState(false);

	useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (successScreen !== null) {
			timeout = setTimeout(() => {
				if (successScreen) {
					setCongrats(true);
					props.setDisplayPoiState(POI_STATE.BOTTOM);
				}
				setSuccessScreen(null);
			}, 1000);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [successScreen, props]);

	const validate = () => {
		let secretCodeInputElem = document.getElementById("secretCodeInput");

		if (secretCodeInputElem) {
			if (
				poi?.codes?.includes(
					(secretCodeInputElem as HTMLInputElement).value.toLowerCase()
				)
			) {
				(secretCodeInputElem as HTMLInputElement).blur()
				setSuccessScreen(true);
			} else {
				let bgelems = document.getElementsByClassName('secretCode');

				for (let elem of bgelems) {
					(elem as any).style.backgroundColor = "#EFBE03";
				}

				let notelem = document.getElementById('notelem') as any;

				if (notelem) {
					notelem.style.opacity = "1";
				}

				let elemsValidate = document.getElementsByClassName('secretCode-validate');

				for (let elem of elemsValidate) {
					(elem as any).style.display = "none"
				}

				let elemsValidate2 = document.getElementsByClassName('secretCode-validate2');

				for (let elem of elemsValidate2) {
					(elem as any).style.display = "flex"
				}

				setTimeout(() => {
					for (let elem of bgelems) {
						(elem as any).style.backgroundColor = "#ede0d3";
					}
	
					if (notelem) {
						notelem.style.opacity = "0";
					}
	
					for (let elem of elemsValidate) {
						(elem as any).style.display = "flex"
					}
	
					for (let elem of elemsValidate2) {
						(elem as any).style.display = "none"
					}
				}, 3000)
			}
		}
	};

	return (
		<div className='secretCode'>
			<div
				className='secretCode-close'
				onClick={() => {
					props.setPoi(null);
					props.setPage("go");
				}}
			></div>
			{successScreen === true && <div className='secretCode-valideBg'></div>}
			{successScreen === false && <div className='secretCode-failureBg'></div>}
			<div className='secretCode-name'>
				<GetTrad lang={language} value={poi?.name} />
			</div>
			<input
				id='secretCodeInput'
				className='secretCode-input'
				type='text'
				placeholder='Code secret'
				onChange={() => {
					let secretCodeInputElem = document.getElementById('secretCodeInput');
				}}
			// onChange={() => {
			// 	validate();
			// }}
			></input>
			<div id="notelem" style={{ opacity: "0" }}><GetTrad lang={language} value='badanswer' /></div>
			<div className='secretCode-validate' onClick={() => validate()}>
				<div className='secretCode-validate-buttonText'>
					<GetTrad lang={language} value='validate' />
				</div>
			</div>
			<div className='secretCode-validate2' style={{ display: "none" }} onClick={() => validate()}>
				<div className='secretCode-validate-buttonText'>
					<GetTrad lang={language} value='validate2' />
				</div>
			</div>
			{congrats && (
				<div className='secretCode-congratsContainer'>
					<div className='secretCode-congratsWhite'></div>
					<div className='secretCode-congrats'>
						<div className='secretCode-congrats-objectName'>
							<GetTrad lang={language} value={"bravo"} />
						</div>
						<div className='secretCode-congrats-found'>
							<GetTrad lang={language} value={poi?.riddle?.discover} />
						</div>
						{/* <div className='secretCode-congrats-objectName'>
							<GetTrad lang={language} value={poi?.riddle.title} />
						</div> */}
						<div className='secretCode-congrats-bookContainer'>
							<div className='secretCode-congrats-book'>
								<div className='secretCode-congrats-bookLeft'>
									<div
										className='secretCode-congrats-desc'
										style={{ textAlign: "center", marginTop: "64px" }}
									>
										{"fig. " + (poi?.parcours && poi.parcours + 1)}
									</div>
									<div
										className='secretCode-congrats-desc'
										style={{
											textTransform: "uppercase",
											textAlign: "center",
											margin: "0 0 16px 0",
											color: "#2B1D16",
											fontFamily: "Ortica Linear",
											fontSize: "16px",
											fontWeight: "400",
											lineHeight: "20px",
										}}
									>
										<GetTrad lang={language} value={poi?.riddle?.title} />
									</div>
									<div className='secretCode-congrats-imgContainer'>
										<div
											className='secretCode-congrats-img'
											style={{ backgroundImage: poi?.riddle?.img }}
										></div>
									</div>

									<div
										className='secretCode-congrats-desc'
										style={{ marginBottom: "64px" }}
									>
										<GetTrad lang={language} value={poi?.riddle?.desc} />
									</div>
								</div>
								<div className='secretCode-congrats-bookRight'></div>
							</div>
						</div>
						<div
							className='continueButton'
							onClick={() => {
								let poiElem = document.getElementById("poi");
								let poiColorElem = document.getElementById("poi-color");

								if (poiElem && poiColorElem) {
									poiElem.classList.remove("poi");
									poiElem.classList.add("poi2");

									setTimeout(() => {
										if (poiElem && poiColorElem) {
											poiElem.style.clipPath = "circle(100% at center 50%)";
											poiColorElem.style.opacity = "0";
										}
									}, 500);
								}
							}}
						>
							<GetTrad lang={props.language} value={"continue"} />
							<div className='secretCode-buttonArrow'></div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
