import React from "react";
import { GetTrad } from "../Partials/GetTrad";
import "./credits.css";
import { useDispatch, useSelector } from "react-redux";
import { resetProgress } from "src/actions/game";
import { IRootState } from "src/reducers";
import { useAuthorizations } from "src/hooks/useAuthorizations";

export const Credits = (props: {
	language: string;
	displayCreditsPage: any;
}) => {
	const { language } = useSelector((state: IRootState) => state.app);

	const dispatch = useDispatch();

	const { askAuthorization, denyAuthorization, geoLoc } = useAuthorizations();

	return (
		<div className='credits'>
			

			<div className='credits-content'>
			<div
				className='ui-top-button ui-top-button-back'
				style={{ position: "absolute", zIndex: "300", marginTop: "64px" }}
				onClick={() => props.displayCreditsPage(false)}
			></div>
			<div
				className='credits-lang'
				style={{ position: "absolute", zIndex: "300", marginTop: "64px" }}
				onClick={() => alert("lang")}
			>
				<div className='credits-langSelectText'>
					<div className='credits-langSelectFlag'></div>
					Français
					<div className='credits-langSelectArrow'></div>
				</div>
			</div>
				<div className='credits-title'>
					<GetTrad lang={language} value='creditsTitle' />
				</div>
				<div className='credits-bold'>
					<GetTrad lang={props.language} value={"immersiveGame"} />
				</div>
				<div className='credits-regular'>
					<GetTrad lang={props.language} value={"manyMarquage"} />
				</div>

				<div className="credits-geoloc">
					<div className="credits-switch">
						{geoLoc && <div className="credits-switchOutside" onClick={() => {
							denyAuthorization();
						}}>
							<div className="credits-switchInside"></div>
						</div>}
						{!geoLoc && <div className="credits-switchOutsideDeny" onClick={() => {
							askAuthorization();
						}}>
							<div className="credits-switchInsideDeny"></div>
						</div>}
					</div>
					<div className="credits-geoloc-text">
						<div className="credits-geoloc-textBold"><GetTrad lang={props.language} value={"playingInPlace"} /></div>
						<div className="credits-geoloc-textRegular"><GetTrad lang={props.language} value={"cutGeoloc"} /></div>
					</div>
				</div>

				<div
					className='resetButton'
					onClick={() => {
						dispatch(resetProgress())
						props.displayCreditsPage(false)
					}}
				>
					<GetTrad lang={props.language} value={"resetExp"} />
				</div>

				<div className='credits-subtitle'>
					<GetTrad lang={language} value='credits' />
				</div>
				<div className='credits-bold'>
					<GetTrad lang={props.language} value={"timescopeProject"} />
				</div>
				<div className='credits-regular'>
					<GetTrad lang={props.language} value={"creditsContent"} />
				</div>
				<div className='credits-regular' style={{ marginTop: "6px" }}>
					<GetTrad lang={props.language} value={"creditsPhotos"} />
				</div>
				<div className='credits-regular' style={{ marginTop: "6px" }}>
					<GetTrad lang={props.language} value={"creditsTexts"} />
				</div>
				<div className='credits-regular' style={{ marginTop: "6px" }}>
					<GetTrad lang={props.language} value={"creditsDesign"} />
				</div>
				<div style={{height: "64px", width: "100%"}}></div>
			</div>
		</div>
	);
};
