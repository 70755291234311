// React
import React, { useEffect, useState } from "react";
// Components
import { GetTrad } from "../Partials/GetTrad";
//Style
import "./poi.css";
// Types
import { POI_STATE } from "src/types/poi_state";
import { useDispatch, useSelector } from "react-redux";
import {
	setFoundGlossary,
	setFoundPoi,
	setPlaceFoundGlossary,
} from "src/actions/game";
import { useCookies } from "react-cookie";
import { IRootState } from "src/reducers";
import { Slider } from "./Slider";

export const Poi = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);

	const dispatch = useDispatch();

	const { glossary, pois } = useSelector((state: IRootState) => state.game);

	const [poi] = useState(pois.find((p: any) => p.id === props.poi));

	useEffect(() => {
		for (let word of glossary) {
			if (word.unlocks.includes(props.poi)) {
				dispatch(setFoundGlossary(word.id));
				dispatch(setPlaceFoundGlossary([word.id, props.poi]));
			}
		}
		dispatch(setFoundPoi(props.poi));
	}, [props.displayPoiState]);

	useEffect(() => {
		if (props.auto) {
			setTimeout(() => {
				let poiElem = document.getElementById("poi");
				let poiColorElem = document.getElementById("poi-color");

				if (poiElem && poiColorElem) {
					poiElem.classList.remove("poi");
					poiElem.classList.add("poi2");

					setTimeout(() => {
						if (poiElem && poiColorElem) {
							poiElem.style.clipPath = "circle(100% at center 50%)";
							poiColorElem.style.opacity = "0";
						}
					}, 500);
				}
			}, 100);
		}
	}, []);

	const [slider, showSlider] = useState(true);

	return (
		<React.Fragment>
			<div
				id={"poi"}
				className='poi'
				style={{ backgroundImage: poi.background }}
				onClick={() => {
					let poiElem = document.getElementById("poi");
					let poiColorElem = document.getElementById("poi-color");

					if (poiElem && poiColorElem) {
						poiElem.classList.remove("poi");
						poiElem.classList.add("poi2");

						setTimeout(() => {
							if (poiElem && poiColorElem) {
								poiElem.style.clipPath = "circle(100% at center 50%)";
								poiColorElem.style.opacity = "0";
							}
						}, 500);
					}
				}}
			>
				{slider && <Slider slider={slider} poi={poi} orientation='' showSlider={showSlider} />}
				<div className='poi-uiHeader' onClick={() => {}}>
					<div
						className='poi-filter'
						onClick={() => {
							showSlider(true);
						}}
					>
						<div className='poi-filterTime'></div>
						<div className='poi-filterText'>
							<GetTrad lang={language} value='temporalFilter' />
						</div>
					</div>
					<div
						className='poi-close'
						onClick={() => {
							props.setPoi(null);
							props.setDisplayPoiState(POI_STATE.NONE);
							props.setPage("go");
						}}
					></div>
				</div>

				<div id='poi-color' className='poi-color'>
					<div className='poi-colorInside'></div>
				</div>
				<div className='poi-header'>
					<div className='poi-numberContent'>
						<div className='poi-number'>
							{poi?.number}
							<div className='poi-numberOn'>/06</div>
						</div>
					</div>
					<div className='poi-text'>
						<div className='poi-title'>
							<GetTrad lang={language} value={poi?.title} />
						</div>
						<div className='poi-subtitle'>
							<GetTrad lang={language} value={poi?.subtitle} />
						</div>
					</div>
				</div>
				<div className='poi-content'>
					<div className='poi-body'>
						{poi?.content?.map((c: any, index: number) => {
							switch (c.type) {
								case "bloc":
									return (
										<div
											key={"poi-content-" + index}
											className='poi-section-bloc'
										>
											{c.content.map((ct: any, index2: number) => {
												return (
													<React.Fragment
														key={"poi-content-" + index + "_" + index2}
													>
														<div
															key={"poi-content-" + index + "_" + index2}
															className='poi-section-bloc-title'
															style={{
																margin:
																	index2 === 0
																		? "24px 24px 0 24px"
																		: "0 24px 0 24px",
															}}
														>
															<GetTrad
																lang={language}
																value={ct.title}
																links={
																	c.words
																		? c.words.map((w: any) => {
																				return () => props.setGlossaryPopup(w);
																		  })
																		: []
																}
															/>
														</div>
														<div className='poi-section-bloc-subtitle'>
															<GetTrad
																lang={language}
																value={ct.subtitle}
																links={
																	c.words
																		? c.words.map((w: any) => {
																				return () => props.setGlossaryPopup(w);
																		  })
																		: []
																}
															/>
														</div>
													</React.Fragment>
												);
											})}
											<div
												className='poi-section-encart-subtitle'
												style={{ marginTop: "0px" }}
											>
												{c.words &&
													c.words.map((word: any, i: number) => {
														return (
															<span
																className='link'
																style={{ fontWeight: "600" }}
																onClick={() => props.setGlossaryPopup(word)}
															>
																<GetTrad
																	lang={language}
																	value={word}
																	format={"minus"}
																/>
																{i < c.words.length - 1 ? " - " : ""}
															</span>
														);
													})}
											</div>
										</div>
									);
								case "encart":
									return (
										<div
											key={"poi-content-" + index}
											className='poi-section-bloc'
										>
											<div
												className='poi-section-title'
												style={{
													margin: "24px 24px 0 24px",
												}}
											>
												<span style={{ marginRight: "16px" }}>
													<GetTrad lang={language} value={c.header} />
												</span>
												<div className='poi-section-titleLine'></div>
											</div>
											<div className='poi-section-encart-title'>
												<GetTrad lang={language} value={c.title} />
											</div>
											<div className='poi-section-encart-subtitle'>
												<GetTrad
													lang={language}
													value={c.subtitle}
													links={
														c.words
															? c.words.map((w: any) => {
																	return () => props.setGlossaryPopup(w);
															  })
															: []
													}
												/>
											</div>
											<div
												className='poi-section-encart-subtitle'
												style={{ marginTop: "0px" }}
											>
												{c.words &&
													c.words.map((word: any, i: number) => {
														return (
															<span
																className='link'
																style={{ fontWeight: "600" }}
																onClick={() => props.setGlossaryPopup(word)}
															>
																<GetTrad
																	lang={language}
																	value={word}
																	format={"minus"}
																/>
																{i < c.words.length - 1 ? " - " : ""}
															</span>
														);
													})}
											</div>
										</div>
									);
								case "img":
									return (
										<div
											key={"poi-content-" + index}
											className='poi-section-bloc'
										>
											<div
												className='poi-section-title'
												style={{
													margin: "24px 24px 0 24px",
												}}
											>
												<GetTrad lang={language} value={c.title} />
											</div>
											<div
												key={"poi-content-" + index}
												className='poi-section-img'
												style={{ aspectRatio: c.ratio, backgroundImage: c.url }}
											></div>
										</div>
									);
								default:
									return <div key={"poi-content-" + index} className=''></div>;
							}
						})}
						<div
							className='poi-start-button'
							onClick={() => {
								props.setPoi(null);
								props.setDisplayPoiState(POI_STATE.NONE);
								props.setPage("go");
							}}
						>
							<div className='poi-start-buttonText'>
								<GetTrad lang={language} value='backMap' />
								<div className='poi-start-buttonArrow'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
