import flagFr from "../style/assets/splashscreen/flags/fr.png";
import flagEn from "../style/assets/splashscreen/flags/en.png";
import flagDe from "../style/assets/splashscreen/flags/de.png";

export const trads = [
	{
		id: "fr",
		flag: `url(${flagFr})`,
		lang: "Français",
		/* Splashscreen */
		mainTitle: "Furfooz's Odyssey",
		mainTitleLine1: "Furfooz's",
		mainTitleLine2: "Odyssey",
		mainSubtitle: "Sur les traces de l'Homme",
		start: "Démarrer",
		/* Menu */
		timeline: "Frise",
		map: "Carte",
		glossary: "Lexique",
		/* Map */
		repereMap: "Repérez-vous sur la carte",
		enjoyExp:
			"Pour profiter à fond de l'expérience Furfooz, nous vous conseillons de partager votre localisation",
		letsgo: "C'est parti !",
		notNow: "Je ne suis pas sur place",
		ok: "Ok",
		dotInactiv1: "Ce point n'est pas encore actif !",
		dotInactiv2: "Vous devez d'abord déverouiller les points précédents.",
		restartExp: "Recommencer l'expérience ?",
		/* Credits */
		creditsTitle: "Furfooz's Odyssey",
		immersiveGame:
			"Un jeu immersif pour découvrir les trésors de Furfooz sur les traces d’Edmond, le célèbre archéologue belge.",
		manyMarquage:
			"Rendez-vous sur les différents marqueurs du parcours et trouvez sur place le code secret qui révélera des contenus exclusif et de nombreuses surprises !",
		playingInPlace: "Jouer en mode \"à distance\"",
		cutGeoloc: "Couper la géolocalisation et accéder aux contenus sans répondre aux énigmes",
		resetExp: "Réinitialiser l'expérience",
		credits: "Crédits",
		timescopeProject: "Un projet Timescope",
		creditsContent: "%bContenus%b Musée de l'homme de Bruxelles",
		creditsPhotos: "%bPhotographie%b Photographe",
		creditsTexts: "%bTextes %bOona Farell",
		creditsDesign: "%bDesign de l'application mobile%b Felix Marquette",
		/* SecretCode */
		validate: "Valider",
		validate2: "Ré-essayer",
		badanswer: "Ce n'est pas ça...",
		bravo: "Bravo !",
		continue: "Continuer",
		slideToDiscover: "Cliquer pour continuer",
		/* Poi */
		test: "Lorem ipsum...",
		legend: "Légende de l'image",
		backMap: "Retour à la carte",
		knowMoreWith: "En savoir plus sur ",
		backTime: "Remonter le temps",
		nowTime: "Retour au présent",
		temporalFilter: "Filtre temporel",
		/* Timeline */
		timelineTitle: "Frise Chronologique",
		scrollArrowsExpl:
			"Pssst... Si vous êtes fatigués de scroller, utilisez les flèches pour vous déplacer plus rapidement d'une période à l'autre !",
		explanationTimeline:
			"L'époque moderne ne représente qu'une petite fraction de l'histoire de l'humanité. Serez-vous capable de remontrer jusqu'aux traces des premiers humains à Furfooz dans cette frise où chaque pixel correspond à 1 an ?",
		timelineBottom: "Vous êtes arrivés au bout ! Bravo :)",
		timelineBackFutur: "Retour vers le futur",
		/* Glossary */
		explanationGlossaryLine1:
			"Voici les termes techniques que vous avez pu rencontrer jusqu'à présent.",
		explanationGlossaryLine2:
			"Découvrez d'autres points d'intérêts pour remplir votre lexique !",
		unlockTo: "Débloqué aux ",
		glossaryUnlock: "Nouveau terme ajouté au lexique !",
		goGlossary: "Voir le lexique",
		/* Cookies */
		cookiesTitle: "Ce site internet utilise des cookies",
		cookiesContent:
			"Nous utilisons des cookies pour sauvegardez vos progrès dans le jeu. En poursuivant l’expérience vous acceptez notre usage de cookies.",
		cookiesAccept: "Accepter",
		cookiesDenied: "Refuser",
		cookiesReadPrivacyPolicy: "Lire notre politique de confidentialité.",
		/* PrivatePolicy */
		privatePolicyTitle: "Politique de confidentialité",
		/* EndExp */
		welldone: "Beau travail !",
		allfound: "Vous avez trouvé tous les trésors de Furfooz.",
		allfound2: "Edouard Dupont serait fier de vous.",
		shareExp: "Partagez votre expérience",
		shareExpWithFriends: "Partagez votre expérience avec vos amis !",
		link: "Lien",
		x: "X",
		whatsapp:"Whatsapp",

		/* ********** */
		/* Quiz Datas */
		/* ********** */

		/* thermalBaths */
		thermalBathsRiddleDisc: "Vous avez résolu la première énigme !",
		thermalBathsRiddleTitle: "Pierre calcaire",
		thermalBathsRiddleDesc:
			"Fantastique ! En effet, les thermes sont bâtis à l’aide de pierres calcaires. Pour lier l’ouvrage, les Romains ont utilisé du mortier de teinte grisâtre compact et du fin gravier de rivière. Et puis, le plateau de Hauterecenne est fait de pierres calcaires, il n’y a plus qu’à se servir.",

		/* Hauterecenne */
		hauterecenneRiddleDisc: "Félicitations pour cette deuxième énigme !",
		hauterecenneRiddleTitle: "Pièce frappée",
		hauterecenneRiddleDesc:
			"Bien joué ! Sur le plateau de Hauterecenne, les archéologues ont découvert des pièces de monnaie en grande quantité… et traversant bien des siècles !",

		/* Nutons */
		nutonsRiddleDisc: "Encore une énigme réussie !",
		nutonsRiddleTitle: "Bois de renne gravé",
		nutonsRiddleDesc:
			"Bravo ! Au Trou des Nutons, on a découvert un bois de renne sur lequel est gravé une tête de bison, et lorsqu’on le retourne, on y voit un mammouth !",

		/* Frontal */
		frontalRiddleDisc: "Vous avez résolu la quatrième énigme !",
		frontalRiddleTitle: "Os frontal d’un adolescent",
		frontalRiddleDesc:
			"Félicitation ! Edouard Dupont a découvert en 1864 un os frontal appartenant à un adolescent ainsi que deux crânes humains intacts conservés dans un ossuaire.",

		/* GoldenPaddle */
		goldenPaddleRiddleDisc: "Encore une énigme, continuez ainsi !",
		goldenPaddleRiddleTitle: "Bout d’une roche avec des sédiments",
		goldenPaddleRiddleDesc:
			"Bravo ! Ce bout de roche est une source d’information précieuse pour les paléontologues. À l’intérieur, on y voit des sédiments, un dépôt qui se forme par la précipitation des matières en suspension ou dissoutes dans un liquide. Grâce à eux, nous pouvons dater le passage de l’Homme dans la grotte.",

		/* Reuviau */
		reuviauRiddleDisc: "C'était la dernière énigme !",
		reuviauRiddleTitle: "Silex taillé magdalénien",
		reuviauRiddleDesc:
			"Fantastique ! Vous êtes arrivés à la fin de votre parcours. Les silex taillés sont les outils emblématiques de la Préhistoire. Les cavités de Furfooz ont livré de belles séries de silex taillés magdaléniens et du Néolithique moyen.",

		/* ************** */
		/* Timeline Datas */
		/* ************** */

		/* Modern */
		timelineModern: "Epoque moderne",
		today: "Aujourd'hui",
		littleSticksOnSide:
			"Vous voyez ces petits traits sur le coté gauche de l'écran ? Chaque trait marque 10 ans sur cette frise chronologique qui remonte jusqu'aux toutes premières traces de l'homme.",
		/* Classical Middle Ages */
		timelineClassicalMiddleAges: "Moyen-Âge Classique",
		americaDiscover: "Découverte de l'amérique",
		romanFall: "Chute de l'empire romain",
		classicalMiddleAgesExpl:
			"Aussi appelée Moyen-Âge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
		classicalMiddleAgesStart: "Début du moyen age",
		classicalMiddleAgesEnd: "Fin du moyen age",
		/* Antiquity */
		timelineAntiquity: "Antiquité",
		antiquityDuration: "Période qui s’étend de l’invention de l’écriture au début du Moyen-Âge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		basEmpireExpl: "Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen-Âge.",
		timelineNeolithic: "Néolithique",
		neolithicDuration: "Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		/* Paleolithic */
		timelinePaleolithic: "Paléolithique",
		paleolithicDuration:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		timelineMagdalenian: "Magdalénien",
		magdalenianDuration:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		startPeriod: "start",
		endPeriod: "end",

		/* ********** */
		/* Pois Datas */
		/* ********** */

		/* parking */
		parking: "Parking",

		/* accueil */
		accueil: "Accueil",

		/* thermalBaths */
		thermalBaths: "Thermes gallo-romains",
		thermalBathsTitle: "Thermes gallo-romains",
		thermalBathsTitleDeter: "les Thermes gallo-romains",
		thermalBathsSubtitle:
			"Les thermes, un lieu incontournable de la vie à l’époque romaine/dans le quotidien des romains !",
		thermalBathsSec1Title: "Furfooz romain",
		thermalBathsSec1Content:
			"En toute terre où les romains s’installent, il est un lieu incontournable : les thermes. Furfooz ne déroge pas à la règle ! Et oui, au Bas-Empire, après la conquête romaine de l’Europe, le plateau de Hauterecenne devient un fortin très important occupé par des auxiliaires barbares au service de Rome. Il faut donc y construire des infrastructures…",
		thermalBathsSec2Title: "Comment ça fonctionne, des thermes ?",
		thermalBathsSec2Content:
			"D’une superficie modeste de 75m2, le bâtiment comprend tous les éléments traditionnels de ces établissements romains, à savoir : une salle au bain chaud (caldarium) et au bain tiède (tepidarium), ainsi qu’une salle au bain froid (frigidarium). La pièce principale est chauffée par %lhypocauste%l, tandis que le bain froid est situé à l’écart, en saillie sur une petite pièce quadrangulaire.",
		thermalBathsEncart1Title: "Pourquoi donc un établissement thermal ici ?",
		thermalBathsEncart1Content:
			"Il plane bien des mystères autour de la présence de thermes sur le site de Furfooz : De quelle période sont-ils datés? Comment les romains ont-ils réussi à acheminer l’eau jusqu’à l’édifice? Comment expliquer l’isolement total en cet endroit de cet ensemble thermal du Haut-Empire ? Antérieur au IVe siècle, certains auteurs justifient son existence par la présence possible d’une villa Gallo-romaine à proximité, mais aucune %lprospection%l n’a jamais pu en localiser l’emplacement. En ce qui concerne l’acheminement de l’eau, les hypothèses archéologiques vont bon train. En l’absence de sources sur le plateau, le géologue Van de Poel envisage par exemple l’existence d’une conduite d’eau qui aurait alimenté les thermes au départ d’une source située à quelques 600m delà, au Fond-des-Vaux. Les %larchéologues%l ont encore de nombreuses questions à élucider !",
		thermalBathsSec3Title: "Plus qu’un bain, un lieu social et civique !",
		thermalBathsSec3Content:
			"Si les thermes ont pour fonction première d’assurer l’hygiène de la cité, ces bains publics sont aussi un haut lieu de sociabilité. Dans la société romaine, on y va tous les jours pour y rencontrer ses amis ou encore y faire du sport, et ce qu’importe son statut social ! Ils sont ouverts à tous, sans distinction de classe sociale, aux hommes comme aux femmes (dans des parties ou à des heures différentes).",
		thermalBathsEncart2Title:
			"L’archéologie expérimentale, une aventure scientifique !",
		thermalBathsEncart2Content:
			"Ils sont passionnés ces archéologues ! D’abord exploré en 1876-1877 par Alfred Bequet de la Société archéologique de Namur, il faut attendre le XXe siècle, de 1956 à 1958, pour qu’on entreprenne une reconstruction fidèle et grandeur nature des thermes romains. Grande aventure scientifique, l’archéologie expérimentale permet de reconstituer ces édifices disparus, de la façon la plus rigoureuse possible, et ainsi rendre accessible la pratique et l’Histoire à un large public. Un bel exemple de pédagogie !",
		thermalBathsSec4Title:
			"Quand l’horizon du Moyen- ge sonne le glas des thermes gallo-romains",
		thermalBathsSec4Content:
			"Si les thermes sont parfaitement visibles grâce à leurs reconstructions, l’histoire du site est bien plus complexe. En effet, à l’orée du Moyen- ge, le plateau de Hauterecenne est progressivement abandonné par le camp romain. Vers le dernier tiers du IVe siècle, le site des thermes va alors prendre une nouvelle fonction, celle d’une petite %lnécropole%l, une fois le bâtiment détruit.",
		thermalBathsEncart3Title:
			"Bâtir une nécropole sur les ruines de thermes gallo-romain",
		thermalBathsEncart3Content:
			"Pour enterrer ses morts, la communauté occupant le site fortifié à la fin du IVe siècle jette son dévolu sur l’espace thermal, devenu terrain vague. La surface nécessaire pour y enfouir les défunts n’est pas très grande puisqu’on compte environ vingt-cinq sépultures fouillées. Les tombes sont creusées à même le sol, sans fondation ou infrastructure. L’étude de cette nécropole tardive dit beaucoup de choses de ses habitants, notamment la découverte de nombreux objets. Car oui, les haches laissent penser que la population de Furfooz seraient de nouveaux arrivants barbares, mais les beaux ceinturons enterrés appartiennent plus à la culture militaire romaine tardive. D’autres trouvailles compliquent l’identification certaine de ce peuple. Quoi qu’il en soit, c’est un beau mélange de culture à Furfooz !",
		thermalBathsSec5Title:
			"Actualité de l’archéologie, le site à nouveau fouillé !",
		thermalBathsSec5Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Hauterecenne */
		hauterecenne: "Plateau fortifié de Hauterecenne",
		hauterecenneTitle: "Plateau fortifié de Hauterecenne",
		hauterecenneTitleDeter: "le Plateau fortifié de Hauterecenne",
		hauterecenneSubtitle: "Une forteresse, plusieurs vies !",
		hauterecenneQuote1:
			"“L’homme, à toutes les époques, s’est établi dans cette localité [Furfooz] suivant les diverses conditions de son temps.”",
		hauterecenneQuoteAuthor1:
			"Edouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		hauterecenneSec1Title:
			"Les traces de fortifications scrutées à la loupe tardivement",
		hauterecenneSec1Content:
			"Le champ de recherche préféré à Furfooz, c’est la paléontologie. Et pourtant, il existe d’autres traces de la présence humaine bien plus tardives et qui méritent une pleine attention ! Le plateau de Hauterecenne, au cœur de cette activité humaine du %lBas-Empire%l et du Haut Moyen Âge, est fouillé marginalement au XIXe siècle, mais il faut attendre les années 1930, puis 1980 pour qu’il fasse l’objet d’études approfondies.\nEn route pour découvrir tous ses secrets !",
		hauterecenneSec2Title:
			"Hauterecenne, un plateau stratégique pour l’installation… d’une garnison romaine !",
		hauterecenneSec2Content:
			"En étudiant les traces des anciens remparts, les archéologues ont mis au jour la présence d’une garnison romaine du Bas-Empire sur le site de Furfooz. Mais pourquoi s’installer ici ? Pour sa situation stratégique bien-sûr ! Le plateau de Hauterecenne est un haut promontoire dominant la rivière, qui dispose d’une protection naturelle de ce côté mais aussi sur son flanc où le Ry des Vaux a lui-même creusé une petite vallée escarpée. Ainsi, situé au-dessus d’une falaise abrupte surplombant la Lesse de 60 mètres, il est très facile de fortifier l site et de se parer contre toute attaque ennemie ! Le plateau porte encore les traces bien conservées d’un certain nombre de structures anciennes et d’éléments de fortifications de périodes différentes. Les archéologues vous racontent.",
		hauterecenneEncart1Title:
			"Les archéologues étudient ces lieux stratégiques",
		hauterecenneEncart1Content:
			"« Depuis le milieu du XIXème siècle, on y a conduit plusieurs campagnes de fouilles et le grand mérite de Raymond Brulet est d’avoir repris l’étude du site [...]. Le site a été ainsi occupé depuis l’époque romaine jusqu’au XIIIème siècle avec des temps forts au milieu du IIIème siècle et au début du Vème siècle. » Raymond Brulet, La fortification de Hauterecenne à Furfooz, (publication d'histoire de l'Art et d'Archéologie de l'Université catholique de Louvain XIII), 1978",
		hauterecenneSec3Title: "Puis d’une petite forteresse médiévale !",
		hauterecenneSec3Content:
			"Raymond Brulet nous met sur la piste… La forteresse aurait donc évolué au fil du temps ? Et oui, après la chute de l’Empire romain et le départ de ses troupes, le site se fortifie épisodiquement. Nous n’avons que peu d’informations quant aux habitants qui s’y installèrent, en revanche la présence d’une maison forte et l’ajout de remparts suggèrent l’installation d’un petit seigneur régional. À la fin du XIIIème siècle, le site perd de son importance stratégique et est progressivement abandonné par l’Homme.",
		hauterecenneEncart2Title: "Savez-vous ce qu’est une maison forte ?",
		hauterecenneEncart2Content:
			"La maison forte est l’ancêtre des châteaux forts. De taille modeste, il n’est possible d’y pénétrer que par le 1er étage. Ainsi, si la maison est attaquée, l’escalier peut être retiré et les occupants protégés !",
		hauterecenneSec4Title:
			"Une superposition d’époques, comment faire pour les distinguer ?",
		hauterecenneSec4Content:
			"Pour retracer l’histoire et l’évolution de l’occupation humaine sur un site, l’archéologue outillé, mais s’entoure de spécialistes. Dans le cas du plateau fortifié, il faut pouvoir distinguer les époques, alors qu’elles se superposent. On travaille alors sur une chronologie relative et on fait appel à des comparaisons site par site. Ce long cheminement a permis de faire une découverte importante : la présence de deux murs de barrage.",
		hauterecenneSec5Title: "L’affaire des deux murs de barrage",
		hauterecenneSec5Content:
			"Et oui, les différentes époques sont visibles sur les murs ! Le premier mur de barrage, le plus extérieur, date du début du Bas-Empire romain. Très étroit et construit selon la même technique que les thermes, cela laisse à penser qu’il devait être muni soit d’un talus de terre appuyé contre lui, soit d’un parapet en bois, ancré dans le mur. Pour une raison que l’on ignore, un second mur de barrage a été élevé ensuite. Il bénéficie d’une large porte charretière et son épaisseur est cette fois plus commune. Il remonte sans doute au Ve siècle. Lors de la dernière mise en défense du site, à l’époque du Moyen- ge classique, ce mur a été bouché, puis surélevé. Son système de circulation au sommet de l’infrastructure repose sur un série de piliers à arcatures.",
		hauterecenneSec6Title: "Et si on fouillait encore ce lieu ?",
		hauterecenneSec6Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Nutons */
		nutons: "Trou des Nutons",
		nutonsTitle: "Trou des Nutons",
		nutonsTitleDeter: "le Trou des Nutons",
		nutonsSubtitle: "Entre découvertes paléontologiques et légende populaire",
		nutonsSec1Title: "Le Trou des nutons, première grotte explorée",
		nutonsSec1Content:
			"En 1864, notre cher Edouard Dupont se lance en premier dans la fouille du trou des Nutons. S’il le choisit comme point de départ de son exploration, c’est parce qu’il présente toutes les caractéristiques idéales à l’implantation d’un campement magdalénien : bien abrité, logé à 40 m au-dessus du lit de la rivière donc proche d’une source d’eau… parfait pour cette population semi-nomade de chasseurs de rennes ou de chevaux !",
		nutonsSec2Title: "Quand l’étude des sols rencontre l’étude des Hommes",
		nutonsSec2Content:
			"Mais comment diable Edouard Dupont tire-t-il autant de conclusions au sujet d’une population si lointaine ? En effectuant ce l’on appelle en archéologie un sondage en archéologie. Sur une toute petite partie de la grotte, l'équipe de Dupont retire les couches successives du sol, jusqu’à 8 m de profondeur pour les étudier. Ces couches sont composées de sédiments, c’est-à-dire de dépôts organiques qui en disent beaucoup sur la période d’occupation et les modes de vie de nos ancêtres. Indéniablement, géologie et archéologie sont intimement liées.",
		nutonsSec3Title: "Des premières grandes découvertes",
		nutonsSec3Content:
			"Edouard Dupont ne s’arrête pas aux sondages ! Il fait dynamiter les stalagmites et les blocs de pierre. Sous ceux-ci, il fait de grandes découvertes : des débris  d’une vingtaine d’espèces animales, des couteaux et des flèches en silex, des pièces de monnaie de l’époque gallo-romaine et divers ustensiles postérieurs. Par contre, il n’y a pas la moindre trace d’ossements humains… Pourtant, tout portait à croire que la découverte de crânes était possible !",
		nutonsSec4Title: "Le %lpaléontologue%l écoute les légendes populaires",
		nutonsSec4Content:
			"Et oui ! Si Edouard Dupont fait le choix de ce trou pour commencer son exploration, c’est aussi car une légende lui est associée… Selon la croyance populaire, les Nutons étaient des nains à l’aspect vieux et chétif, mais actifs, très malicieux et fort habiles de leurs mains. Ils vivaient cachés dans les rochers, les cavernes et les ruines. Pendant la nuit, pour un peu de nourriture et quelques friandises déposées à l’entrée de leurs  gîtes, ces êtres surnaturels réparaient les ustensiles, les objets mobiliers, raccommodaient des haillons et blanchissaient le linge des villageoises. À la belle saison, ils sortaient en nombre pour se livrer à de joyeuses danses dans les prés. Taquins, ils pouvaient être très susceptibles et se vengeaient de ceux qui les bernaient ou cherchaient à découvrir leurs secrets.",
		nutonsEncart1Title: "Une explication scientifique de la légende ?",
		nutonsEncart1Content:
			"Edouard Dupont tente d’expliquer la petite taille des populations de Furfooz : « Les hommes de Grenelle, et surtout ceux de Furfooz, étaient de petite taille. Les premiers alignaient encore une moyenne d’1m62, mais les seconds descendaient à 1m53. C’est presque exactement la taille moyenne des Lapons. Toutefois, cette stature réduite n’excluait ni la vigueur ni l’agilité nécessaire aux populations sauvages. Les os des membres et du tronc sont robustes, et les saillies, les dépressions de leur surface, accusent un développement musculaire très prononcé. À part cette robusticité générale, supérieure à ce que l’on rencontre habituellement, les squelettes des hommes de Furfooz et de Grenelle ressemblent fort à celui des hommes d’aujourd’hui » (Edouard Dupont, « Sur les crânes de Furfooz », Compte-rendu du Congrès de Préhistoire, 1872. pp. 251-252).",
		nutonsQuote1:
			"Aussi mystérieux qu’emblématique, le Trou des Nutons a toujours de quoi vous surprendre ! Continuez votre exploration de la réserve pour découvrir bien d’autres secrets.",

		/* Frontal */
		frontal: "Trou du Frontal",
		frontalTitle: "Trou du Frontal",
		frontalTitleDeter: "le Trou du Frontal",
		frontalSubtitle: "Entre habitat et sépulture",
		frontalSec1Title:
			"S’il est appelé Trou du Frontal, c’est grâce à une découverte !",
		frontalSec1Subtitle:
			"À votre avis, pourquoi ce nom ? Tout simplement car notre ami Edouard Dupont, en fouillant le trou en 1864, met la main dès les premiers coups de pioche sur un os frontal appartenant à un adolescent. Cette grande découverte est un événement dans le monde de la recherche et est annonciatrice de bien d’autres…",
		frontalSec2Title:
			"la découverte de deux crânes très différents, une énigme à élucider",
		frontalSec2Subtitle:
			"Le 10 décembre de la même année, le paléontologue dégage, en présence d’invités de marque, deux crânes humains intacts conservés dans un ossuaire. Cela ouvre un champ d’hypothèses immense quant à la période d’occupation de ces grottes, mais aussi aux modes de vie de nos ancêtres.",
		frontalEncart1Title: "Récit de fouilles par Dupont",
		frontalEncart1Content:
			"“Cette petite caverne, tant par ses données géologiques que par les lumières qu’elle a répandues sur l’ethnographie ancienne, est la plus importante qui ait encore été fouillée dans notre pays. Il est certain que si l’explorateur eût souvent vu ses recherches couronnées d’un succès aussi complet, les laborieux travaux, entrepris dans notre région, eussent été fort abrégés, et il resterait aujourd’hui bien peu de chose à dévoiler sur les habitants de la Belgique à l’époque quaternaire. [...] Nous examinerons plus loin les données que les restes des repas dans l’abri extérieur et l’amas d’ossements humains nous fournissent sur les coutumes funéraires de l’antique peuplade.” \n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		frontalSec3Title:
			"Se tromper fait partie de la recherche paléontologique !",
		frontalSec3Subtitle:
			"En étudiant ces ossements, Dupont les date à tort de l’époque des chasseurs de rennes. Mais être paléontologue c’est aussi se tromper ! Des études plus approfondies sur ces crânes et de l’ensemble des matériaux découverts sur le site ont mis au jour une confusion de la part de Dupont entre des traces de Magdaléniens, les fameux chasseurs, et celles de populations vivant au Néolithique.",
		frontalEncart2Title:
			" Quand la théorie de Darwin éclaire le monde paléontologique",
		frontalEncart2Content:
			"Les fouilles entreprises par Dupont sont considérées comme le premier argument anatomique en faveur des théories de  Darwin, retranscrites dans son ouvrage L’origine des espèces publié en 1859, quelques années avant ses découvertes. Grâce à ces crânes, il comprend l’évolution de l’espèce humaine en fonction des périodes d’occupation. Car oui, des Hommes de deux ères vivaient au trou du Frontal !",
		frontalSec4Title: "Des Hommes de deux ères : la vie, la mort",
		frontalSec4Subtitle:
			"Au Trou du Frontal, les fouilles archéologiques ont mis au jour à la fois des silex taillés datant de l’époque %lMagdalénienne%l, mais aussi de nombreux ossements, d’au moins 16 individus (hommes, femmes et enfants) situés nettement au-dessus de la couche du niveau magdalénien. Tout porte à croire qu’il s’agit-là de vestiges d’une sépulture datant du Néolithique moyen. Près de 10 000 ans les séparent et pourtant, tous ont choisi le trou du Frontal pour refuge !",
		frontalSec5Title: "Vivre et mourir au Trou du Frontal",
		frontalSec5Subtitle:
			"L’amas d’ossements suggère un espace funéraire plutôt qu’un habitat. En effet, le procédé d’inhumation, c’est-à-dire d’enterrer ses morts s’étend à tout le territoire actuel de la Belgique pendant le Néolithique, il y a 7000 ans. Le Trou du Frontal en est un parfait exemple puisqu’il est même fermé par une dalle, comme on le ferait pour une tombe, ce qui confirme qu’il s’agit-là d’une sépulture secondaire datant du Néolithique moyen.",
		frontalSec6Title:
			"Du trou du Frontal au trou de la Mâchoire… il n’y a que 10 mètres à parcourir !",
		frontalSec6Subtitle:
			"La pratique funéraire est très présente sur le site de Furfooz. À quelques pas de trou du Frontal, le trou de la Mâchoire avait sans doute la même fonction puisqu’on y a découvert cinq squelettes dans un état remarquable. Et, nous le savons, étudier les morts c’est comprendre la vie !\nPassez à la buvette située non loin du trou du Frontal pour profiter pleinement des beautés de la nature.",

		/* GoldenPaddle */
		goldenPaddle: "Grotte de la Gatte d'Or",
		goldenPaddleTitle: "Grotte de la Gatte d’Or",
		goldenPaddleTitleDeter: "la Grotte de la Gatte d’Or",
		goldenPaddleSubtitle:
			"Une mine d’informations sur l’histoire de l’humanité !",
		goldenPaddleSec1Title:
			"Pourquoi diable étudier la roche pour en apprendre sur les Hommes ?",
		goldenPaddleSec1Subtitle:
			"“Les études de cette nature, qui puisent leurs éléments dans les sciences géologiques, sont donc du ressort exclusif des sciences naturelles. Par ces investigations, la géologie donne la main à l’histoire et l’aide à restaurer le passé le l’humanité.” Edouard Dupont, Les temps préhistoriques en Belgique : l'Homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse,1872\n\nCes quelques mots de Edouard Dupont transmettent bien l’importance de l’étude de la roche, la sédimentation, dans la compréhension de l’histoire de l’humanité. On vous explique.",
		goldenPaddleSec2Title:
			"La sédimentation, une mine d’or pour les scientifiques !",
		goldenPaddleSec2Subtitle:
			"En décryptant les modifications du milieu naturel, celui que l’on appelle le géomorphologue nous renseigne sur les activités humaines qui l’ont façonné. En étudiant les sédiments, il détermine l’origine des dépôts (alluvions, ruissellements, remblais…) ou leur âge. Il observe aussi la succession des strates de sédiments visible grâce aux changements de couleur ou de texture des sols (argiles, sables, cailloux…). Chaque strate correspondant à une époque, la méthode stratigraphique permet de déterminer la nature et la chronologie des événements intervenus. À la Gatte d’Or, les couches sédimentaires sont très visibles et renseignent aussi bien sur la présence humaine que sur les origines même de Furfooz.",
		goldenPaddleSec3Title:
			"Des fouilles qui détériorent le patrimoine, cela arrive aussi…",
		goldenPaddleSec3Subtitle:
			"Au début du XXe siècle, le site de Furfooz n’est plus habité depuis longtemps mais est un haut lieu touristique payant. Hélas ! Après les nombreuses fouilles de Dupont, rien n’a été prévu pour protéger l’intérieur des grottes. L’afflux des vacanciers n’a fait qu’empirer les choses avec une détérioration à l’intérieur des  grottes dont celle de la Gatte d’or : bris de concrétions calcaires et altération de la teinte des  draperies suite à un mauvais emploi des bougies utilisées pour l’éclairage.",
		goldenPaddleEncart1Title: "La légende raconte…",
		goldenPaddleEncart1Content:
			"La légende du pays raconte qu’une gatte, une chèvre en wallon, vivrait dans les grottes de Furfooz et garderait un trésor… Cette histoire populaire remonte à une lointaine époque, où le tissu manquait et les banques n’existaient pas. Les gens confectionnaient de petits sacs en peau poilue et cachaient leurs deniers où ils pouvaient. Si bien que parfois, ils les oubliaient ou mourraient avec leur secret… Les malheureux sans mémoire font des heureux bien plus tard ! De cette légende naît la fameuse gatte toute d’or vêtue !",
		goldenPaddleSec4Title:
			"Aujourd’hui, la Gatte d’or est un spot naturel incontournable !",
		goldenPaddleSec4Subtitle:
			"Heureusement, l’association Ardenne & Gaume œuvre aujourd’hui pour la préservation de ce patrimoine. La présence humaine se fait quasi invisible et laisse place à un paysage naturel exceptionnel. La vue y est à couper le souffle !",

		/* Reuviau */
		reuviau: "Trou Reuviau",
		reuviauTitle: "Trou Reuviau",
		reuviauTitleDeter: "le Trou Reuviau",
		reuviauSubtitle: "Au Trou Reuviau, on se met à table !",
		reuviauSec1Title: "Reuviau, un trou discret mais riche en savoir",
		reuviauSec1Subtitle:
			"À la fin de votre promenade dans la réserve naturelle de Furfooz, n’oubliez pas de vous arrêter au trou Reuviau !  Modeste, il est pourtant très intéressant pour les paléontologues, notamment pour connaître mieux les pratiques alimentaires des Magdaléniens. On vous explique.",
		reuviauEncart1Title: "Fouilles du Reuviau par E.Dupont",
		reuviauEncart1Content:
			"“Une autre petite caverne, le trou Reuviau, dans un ravin sur le chemin qui conduit de la Lesse à Furfooz, a fourni, dans les mêmes circonstances géologiques, des ossements de cheval, de renne, d’ours, etc., brisés de main d'homme.”\n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec2Title: "Le repas des Hommes à l’âge du renne",
		reuviauSec2Subtitle:
			"Edouard Dupont, puis bien d’autres paléontologues, ont découvert une série d’ossement de rennes et de chevaux qui portent à croire qu’ils étaient des mets de choix pour ces hommes des cavernes… cela a même donné le nom à l’âge du renne, pour dire ! Des silex mis au jour dans le trou en disent beaucoup sur la façon dont ces hommes dépeçaient les bêtes, aussi bien pour se nourrir que pour se vêtir.",
		reuviauEncart2Title: "Dupont défini l’âge de pierre",
		reuviauEncart2Content:
			"“La chasse leur fournissait leurs aliments et leurs grossiers vêtements ; l’os et surtout la pierre-à-feu leur donnaient des outils et des armes, sans qu’elles eussent pu arriver à produire des métaux ou seulement à en soupçonner l’existence. Aussi a-t-on désigné, sous le nom d’âges de pierre, ces époques lointaines et de longue durée.”\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec3Title: "La chasse à la Préhistoire",
		reuviauSec3Subtitle:
			"Durant le paléolithique, le renne, passant en troupeaux dans les vallées, est le gibier de prédilection des hommes préhistoriques. De nombreuses traces de ces chasses ont été retrouvées dans les diverses cavernes de Furfooz. Animal grégaire et migrateur, le renne était sans doute aussi un animal facile à chasser, et à chasser en masse… Mais aussi à faire sécher ! L'ensemble de ces facteurs, étudiés par les paléontologues, nous en apprennent toujours un peu plus sur nos ancêtres !",
		reuviauSec4Title: "L’enquête dans les trous de Furfooz continue !",
		reuviauSec4Subtitle:
			"Vous arrivez à la fin de votre exploration mais, n’en doutez pas, vous n’avez découvert qu’une infime partie de la réserve naturelle de Furfooz ! N’hésitez pas à revenir pour en explorer ses moindres recoins, sa faune et sa flore luxuriantes, protégées depuis près de 70 ans !\nÀ bientôt.",

		furfoozMystery: "Les mystères de Furfooz",
		archaeology: "Archéologie",
		doYouKnow: "Bon à savoir",
		anecdote: "Anecdote",
		edouardDupont: "L'anecdote Dupont",

		/* ************** */
		/* Glossary Datas */
		/* ************** */

		palaeontologist: "Paléontologue",
		palaeontologistDesc:
			"celui qui étudie l'apparition, l'évolution et l'extinction des espèces animales, au long d'une immense période qui remonte aux premières traces de vie sur Terre, il y a environ 3,5 milliards d'années.",
		archaeologist: "Archéologue",
		archaeologistDesc:
			"celui qui étudie les civilisations humaines du passé à partir de traces matérielles qui en subsistent (bâtiments, œuvres d’art, pièces de monnaie, tombes, manuscrits, objets de la vie quotidienne…).",
		geologist: "Géologue",
		geologistDesc:
			"celui qui étudie la composition et la structure des sols et roches de la croûte terrestre. Le but? Expliquer l’évolution de notre planète.",
		anthropologist: "Anthropologue",
		anthropologistDesc:
			"celui qui étudie l’homme, son comportement et son évolution. Sur les chantiers archéologiques, il analyse les squelettes afin de déterminer les conditions de vie et de mort de la personne. Il décrit également les sépultures (type de tombe, parures, offrandes, mobilier) afin de pouvoir reconstituer les gestes funéraires effectués par les proches du défunt.",
		sediment: "Sédiment",
		sedimentDesc:
			"dépôt qui se forme à partir de l’altération ou de la désagrégation de roches anciennes.",
		experimentalArchaeology: "Archéologie expérimentale",
		experimentalArchaeologyDesc:
			"Discipline de l’archéologie, cette discipline consiste à restituer, grâce à l’expérimentation, des bâtiments ou objets selon les techniques de construction et de fabrication d’époque.",
		necropolis: "Nécropole",
		necropolisDesc:
			"Equivalent d’un cimetière à l’Antiquité, révélé par l’archéologie. Les sépultures peuvent être situées dans des excavations souterraines ou être creusées dans le sol.",
		hypocaust: "Hypocauste",
		hypocaustDesc:
			"Local souterrain renfermant un système destiné à chauffer les bains, les salles des thermes ou encore des pièces à vivre.",
		darwinism: "Darwinisme",
		darwinismDesc:
			"Ensemble des théories biologiques de Darwin et de ses disciples selon lesquels la sélection naturelle est le facteur essentiel de la transformation des espèces vivantes.",
		prospecting: "Prospection (archéologie)",
		prospectingDesc:
			"La prospection consiste à observer et étudier un site archéologique sans creuser dans le sol. Elle peut se faire à pied (en ramassant des objets éparpillés à la surface), depuis un avion (pour voir apparaître des anomalies dans le relief) ou encore avec des appareils radars qui donneront une indication sur d'éventuels bâtiments enfouis.",
		ossuary: "Ossuaire",
		ossuaryDesc:
			"Amas d’ossements. Cela peut aussi être un bâtiment où les ossements humains sont entassés.",

		palaeolithic: "Paléolithique supérieur",
		palaeolithicDesc:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		magdalenian: "Magdalénien",
		magdalenianDesc:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		neolithic: "Néolithique",
		neolithicDesc:
			"Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		antiquity: "Antiquité",
		antiquityDesc:
			"Période qui s’étend de l’invention de l’écriture au début du Moyen  ge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		lateRoman: "Bas-Empire",
		lateRomanDesc:
			"Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen-Âge.",
		highMiddleAges: "Haut-Moyen-Âge",
		highMiddleAgesDesc: "Période qui s’étend de la fin de l’Antiquité à la fin de la période carolingienne, soit à la fin du Xe siècle.",
		classicalMiddleAges: "Moyen-Âge classique",
		classicalMiddleAgesDesc:
			"aussi appelée Moyen- ge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
	},
	{
		id: "en",
		flag: `url(${flagEn})`,
		lang: "English",
		/* Splashscreen */
		mainTitle: "Furfooz's Odyssey",
		mainTitleLine1: "Furfooz's",
		mainTitleLine2: "Odyssey",
		mainSubtitle: "In the footsteps of Humanity",
		start: "Start",
		/* Menu */
		timeline: "Timeline",
		map: "Map",
		glossary: "Glossary",
		/* Map */
		repereMap: "Find your way on the map",
		enjoyExp:
			"To get the most out of the Furfooz experience, we recommend that you share your location with us.",
		letsgo: "Here we go!",
		notNow: "I'm not on site",
		ok: "Ok",
		dotInactiv1: "This point is not yet active!",
		dotInactiv2: "You must first unlock the previous points.",
		restartExp: "Repeat the experience?",
		/* Credits */
		creditsTitle: "Furfooz's Odyssey",
		immersiveGame:
			"An immersive game to discover Furfooz's treasures in the footsteps of Edmond, the famous Belgian archaeologist.",
		manyMarquage:
			"Go to the various markers along the route and find the secret code that will reveal exclusive content and lots of surprises!",
		playingInPlace: "Play in \"remote\"",
		cutGeoloc: "Switch off geolocation and access content without answering riddles",
		resetExp: "Reset experiment",
		credits: "Credits",
		timescopeProject: "A Timescope project",
		creditsContent: "%Contents%b Musée de l'homme de Bruxelles",
		creditsPhotos: "%bPhotography%b Photographer",
		creditsTexts: "%bTexts %bOona Farell",
		creditsDesign: "%bMobile application design%b Felix Marquette",
		/* SecretCode */
		validate: "Confirm",
		validate2: "Try again",
		badanswer: "It's not that...",
		bravo: "Bravo!",
		continue: "Continue",
		slideToDiscover: "Click to continue",
		/* Poi */
		test: "Lorem ipsum...",
		legend: "Image caption",
		backMap: "Back to map",
		knowMoreWith: "Find out more about ",
		backTime: "Going back in time",
		nowTime: "Back to the present",
		temporalFilter: "Time filter",
		/* Timeline */
		timelineTitle: "Timeline",
		scrollArrowsExpl:
			"Pssst... If you get tired of scrolling, use the arrows to move more quickly from one period to the next!",
		explanationTimeline:
			"The modern era represents only a small fraction of human history. Will you be able to retrace the footsteps of the first humans in Furfooz in this frieze where each pixel corresponds to 1 year?",
		timelineBottom: "You've reached the end! Well done :)",
		timelineBackFutur: "Back to the future",
		/* Glossary */
		explanationGlossaryLine1:
			"Here are some of the technical terms you may have come across so far.",
		explanationGlossaryLine2:
			"Discover other points of interest to complete your glossary!",
		unlockTo: "Unlocked at ",
		glossaryUnlock: "New term added to the glossary!",
		goGlossary: "New term added to the glossary!",
		/* Cookies */
		cookiesTitle: "This website uses cookies",
		cookiesContent:
			"We use cookies to save your progress in the game. By continuing you agree to our use of cookies.",
		cookiesAccept: "Accept",
		cookiesDenied: "Refuse",
		cookiesReadPrivacyPolicy: "Read our privacy policy.",
		/* PrivatePolicy */
		privatePolicyTitle: "Privacy policy",
		/* EndExp */
		welldone: "Great job!",
		allfound: "You've found all Furfooz's treasures.",
		allfound2: "Edouard Dupont would be proud of you.",
		shareExp: "Share your experience",
		shareExpWithFriends: "Share your experience with your friends!",
		link: "Link",
		x: "X",
		whatsapp:"Whatsapp",

		/* ********** */
		/* Quiz Datas */
		/* ********** */

		/* thermalBaths */
		thermalBathsRiddleDisc: "You've solved the first puzzle!",
		thermalBathsRiddleTitle: "Limestone",
		thermalBathsRiddleDesc:
			"Fantastic! In fact, the thermal baths were built using limestone. To bind the structure, the Romans used compact grey mortar and fine river gravel",

		/* Hauterecenne */
		hauterecenneRiddleDisc: "Congratulations on your second puzzle!",
		hauterecenneRiddleTitle: "Coin struck in the name of Constantine III.",
		hauterecenneRiddleDesc:
			"Well played! On the Hauterecenne plateau, archaeologists have discovered coins… spanning many centuries!",

		/* Nutons */
		nutonsRiddleDisc: "Another successful puzzle!",
		nutonsRiddleTitle: "Reindeer antler on which a bison or mammoth head is engraved by turning it over",
		nutonsRiddleDesc:
			"Well done! At the Nutons Hole, a reindeer antler was discovered on which a bison head is engraved. If you turn it over, you might see a mammoth!",

		/* Frontal */
		frontalRiddleDisc: "You've solved the fourth riddle!",
		frontalRiddleTitle: "Frontal bone of an adolescent",
		frontalRiddleDesc:
			"Congratulations! In 1864, Édouard Dupont discovered a frontal bone belonging to an adolescent, as well as two intact human skulls preserved in an ossuary.",

		/* GoldenPaddle */
		goldenPaddleRiddleDisc: "Another riddle, keep it up!",
		goldenPaddleRiddleTitle: "End of a rock where sediments and geological layers can be seen",
		goldenPaddleRiddleDesc:
			"Congratulations!  This piece of rock is a valuable source of information for paleontologists. Inside, we can see sediment, a deposit that forms by the precipitation of materials suspended or dissolved in a liquid. Thanks to these, we can date the populations occupying the cave.",

		/* Reuviau */
		reuviauRiddleDisc: "That was the last riddle!",
		reuviauRiddleTitle: "Magdalenian chipped flint",
		reuviauRiddleDesc:
			"Congratulations ! You have reached the end of your journey. Flint is the emblematic tool of Prehistory. The Furfooz cavities revealed numerous series of Magdalenian and Middle Neolithic flints.",

		/* ************** */
		/* Timeline Datas */ /* TODO */
		/* ************** */

		/* Modern */
		timelineModern: "Modern period",
		today: "Today",
		littleSticksOnSide:
			"See those little lines on the left-hand side of the screen? Each line marks 10 years on this timeline, going back to the very first traces of man.",
		/* Classical Middle Ages */
		timelineClassicalMiddleAges: "Moyen-Âge Classique",
		americaDiscover: "Découverte de l'amérique",
		romanFall: "Chute de l'empire romain",
		classicalMiddleAgesExpl:
			"Aussi appelée Moyen-Âge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
		classicalMiddleAgesStart: "Début du moyen age",
		classicalMiddleAgesEnd: "Fin du moyen age",
		/* Antiquity */
		timelineAntiquity: "Antiquité",
		antiquityDuration: "Période qui s’étend de l’invention de l’écriture au début du Moyen-Âge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		basEmpireExpl: "Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen-Âge.",
		timelineNeolithic: "Néolithique",
		neolithicDuration: "Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		/* Paleolithic */
		timelinePaleolithic: "Paléolithique",
		paleolithicDuration:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		timelineMagdalenian: "Magdalénien",
		magdalenianDuration:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		startPeriod: "start",
		endPeriod: "end",

		/* ********** */
		/* Pois Datas */
		/* ********** */

		/* parking */
		parking: "Parking",

		/* accueil */
		accueil: "Home",

		/* thermalBaths */
		thermalBaths: "Gallo-Roman baths",
		thermalBathsTitle: "Gallo-Roman baths",
		thermalBathsTitleDeter: "Gallo-Roman baths",
		thermalBathsSubtitle:
			"The thermal baths, an essential location in Roman life",
		thermalBathsSec1Title: "Roman Furfooz",
		thermalBathsSec1Content:
			"In every land where the Romans settled, there was one key location: the thermal baths. Furfooz was no exception to the rule! And yes, in the Late Empire, the Hauterecenne plateau became a very important fortress occupied by a militia that served Rome. So a little infrastructure building was required…",
		thermalBathsSec2Title: "How do thermal baths work?",
		thermalBathsSec2Content:
			"With a modest surface area of 75 m2, the building included all the traditional elements of these Roman establishments, namely: a heated room with a hot bath (caldarium) and a warm bath (tepidarium), as well as a room with a cold bath (frigidarium). The main room was heated by hypocaust, while the cold wing was situated separately, projecting into a small quadrangular room which also served as a changing room.",
		thermalBathsEncart1Title: "Why place a thermal building here?",
		thermalBathsEncart1Content:
			"Many questions remain about the Furfooz thermal baths: from what period do they date? How did the Romans manage to bring water in to the building? If the baths were not linked to a fortress, their presence away from any ancient habitat would be difficult to explain, especially since no Roman villa has been discovered nearby. The use of these baths by the occupants of the plateau is the most probable hypothesis, this is also the case in many comparable military sites dating back to the beginning of the Late Empire. As for the water supply, there is a lot of speculation. In the absence of a spring on the plateau, the geologist Van de Poel suggests, for example, the existence of a water pipe which would have supplied the thermal baths from a source located some 600m away, at Fond-des-Vaux. Archaeologists still have many questions to answer!",
		thermalBathsSec3Title: "More than just a bath, a social and civic place!",
		thermalBathsSec3Content:
			"While the primary function of the thermal baths was to ensure the hygiene of the city, these public baths were also a high place of sociability. In Roman society, people went to them daily to meet with friends or to play sports, regardless of their social status! They were open to everyone, regardless of social class, including both men and women (in different parts or at different times).",
		thermalBathsEncart2Title:
			"Experimental archaeology, a scientific adventure!",
		thermalBathsEncart2Content:
			"Archaeologists are passionate people! First explored in 1876-1877 by Alfred Bequet of the Namur Archaeological Society, it was not until the 1930s that new investigations were carried out at the Furfooz thermal baths by Jacques Breuer.  From 1956 to 1958, with a view to creating a faithful and life-size reconstruction of the Roman baths, a new adventure in experimental archaeology made it possible to reconstruct this lost building, in the most rigorous way possible, and thus to make their practice and history accessible to a wide audience. A great way to teach!",
		thermalBathsSec4Title:
			"When the coming Middle Ages sounded the death knell of the Gallo-Roman baths",
		thermalBathsSec4Content:
			"Although the thermal baths are perfectly clear to see now, thanks to their reconstruction, the history of the site is much more complex. Indeed, at the dawn of the Middle Ages, the Hauterecenne plateau was temporarily abandoned by the occupants of the Roman fortress. Towards the last third of the 4th century, the baths were dismantled and the site became home to a small necropolis.",
		thermalBathsEncart3Title:
			"Building a necropolis in the ruins of Gallo-Roman baths",
		thermalBathsEncart3Content:
			"To bury their dead, the new community occupying the fortified site at the end of the 4th century set its sights on the thermal area, which had become a wasteland. And nothing was wasted, as those materials were reused in the construction of walls on the plateau!\n\nThe area required to bury the deceased was not very large as only twenty-five excavated graves have been counted. The graves were dug into the ground directly, some of them had a coffin. The study of this late necropolis says a lot about its inhabitants, notably the discovery of numerous objects, known as grave goods. These items imply a privileged status of the deceased and testify to a militarised character of the corresponding group. It was wrongly thought that this was a Germanic immigrant community, but the tableware and the beautiful belts belonged to the late Roman military culture. During this period of transition, a certain mix of cultures is to be expected in Furfooz!",
		thermalBathsSec5Title:
			"Archaeology news, the site excavated again!",
		thermalBathsSec5Content:
			"In 2024, a new campaign of preventive excavations is to be organised to continue learning more about our ancestors, their lifestyles and their infrastructure. What will we discover? Only archaeologists will be able to tell us!",

		/* Hauterecenne */
		hauterecenne: "Fortified Hauterecenne plateau",
		hauterecenneTitle: "Fortified Hauterecenne plateau",
		hauterecenneTitleDeter: "the Fortified Hauterecenne plateau",
		hauterecenneSubtitle: "One fortress, many lives!",
		hauterecenneQuote1:
			"“Humanity, across all periods, have inhabited this location [Furfooz] depending on the various conditions of their time.”",
		hauterecenneQuoteAuthor1:
			"Édouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		hauterecenneSec1Title:
			"Fortification ruins examined closely",
		hauterecenneSec1Content:
			"Furfooz's most popular field of research is paleontology.  However, there are other traces of human presence from much later that deserve our full attention!  It was while excavating the Hauterecenne plateau that archaeologists discovered human activity from the Late Empire and the Early Middle Age periods. Partially excavated in the 19th century, it was not until the 1930s and then the 1980s that the plateau was subject to more in-depth studies. Well on the way to discovering its secrets!",
		hauterecenneSec2Title:
			"Hauterecenne, a strategic plateau for the installation… of a Roman garrison!",
		hauterecenneSec2Content:
			"By studying the traces of the ancient ramparts, archaeologists uncovered the presence of a Roman garrison from the Late Empire on the Furfooz site. But why settle here? For its strategic location of course! The Hauterecenne plateau is a high promontory overlooking the river and benefiting from natural protection on this side, but also on its other side, where the Ry des Vaux has carved out a small steep valley. Thus, located above a steep cliff overlooking the Lesse from 60 metres, it is very easy to fortify the site and protect against any enemy attack! The plateau still bears the well-preserved remains of a number of ancient structures and fortification elements from different periods. Archaeologists tell us all.",
		hauterecenneEncart1Title:
			"Archaeologists study these strategic places",
		hauterecenneEncart1Content:
			"\"Since the middle of the 19th century, several excavation campaigns have been carried out and to Raymond Brulet's great credit, the site’s study was resumed […]. The site was thus occupied from the Roman era until the 13th century with peak periods in the middle of the 3rd and the beginning of the 5th centuries.\n\nRaymond Brulet, The fortification of Hauterecenne at Furfooz, (published by the history of Art and Archeology of the Catholic University of Louvain XIII), 1978.",
		hauterecenneSec3Title: "Then a small medieval fortress!",
		hauterecenneSec3Content:
			"Raymond Brulet puts us on the right track... So the fortress evolved over time? Indeed, after the fall of the Roman Empire and the departure of its troops, the site was fortified episodically. We have little information about the inhabitants who settled there, however the presence of a fortified house and the addition of ramparts suggest the installation of a small regional lord. At the end of the 13th century, the site lost its strategic importance and was gradually abandoned by mankind.",
		hauterecenneEncart2Title: "Do you know what a fortified house is?",
		hauterecenneEncart2Content:
			"A fortified house is the ancestor of fortified castles. Modest in size, it can only be entered via the first floor. So if the house is attacked, the staircase can be removed and the occupants protected!",
		hauterecenneSec4Title:
			"A superposition of eras, how to tell them apart?",
		hauterecenneSec4Content:
			"To trace the history and evolution of human occupation on a site, the well-equipped archaeologist connects with a range of specialists. In the case of the fortified plateau, it is necessary to be able to tell the time periods apart, even though they overlap.  Then work begins on a relative chronology and the use of site-by-site comparisons. This long journey allowed us to make an important discovery: the presence of two dam walls.",
		hauterecenneSec5Title: "The case of the two dam walls",
		hauterecenneSec5Content:
			"And yes, the different eras are clearly visible on the walls! The first, outermost, barrier wall dates from the beginning of the Late Roman Empire. Very narrow and built using the same technique as the baths, this suggests that it must have had an earth bank leaning against it or a wooden parapet, anchored in the wall. For some unknown reason, a second dam wall was subsequently built. It has a wide carriage door and its thickness is this time more typical. It most likely dates back to the 5th century. During the last defence of the site, in the High Middle Ages, this wall was filled in and then raised. Its pathway system at the top of the infrastructure is based on a series of arched pillars.",
		hauterecenneSec6Title: "What if we searched this place again?",
		hauterecenneSec6Content:
			"In 2024, a new excavation campaign was organised to continue learning more about our ancestors, their lifestyles and their infrastructures. What will we discover? Only archaeologists will be able to tell us!",

		/* Nutons */
		nutons: "Nutons Hole",
		nutonsTitle: "Nutons Hole",
		nutonsTitleDeter: "Nutons Hole",
		nutonsSubtitle: "between paleontological discoveries and popular legend",
		nutonsSec1Title: "Nutons Hole, the first cave explored",
		nutonsSec1Content:
			"In 1864, our dear Édouard Dupont was the first to embark on the excavation of the Nutons hole. If he chose it as the starting point for his exploration, it was because it had all the ideal characteristics for a Magdalenian camp: well sheltered, located 40 metres above the river bed and therefore close to a water source... perfect for this semi-nomadic population of reindeer and horse hunters!",
		nutonsSec2Title: "When soil studies meet people studies",
		nutonsSec2Content:
			"But how on earth does Edouard Dupont draw so many conclusions about such a distant population? By carrying out what is called in archaeology a survey. In a very small part of the cave, Dupont's team removed successive layers of soil, up to 8m deep, to study them. These layers are made of sediment, the deposits that tell us a lot about the time period under which it was occupied, and the lifestyles of our ancestors. Undeniably, geology and archaeology are closely linked.",
		nutonsSec3Title: "First major discoveries",
		nutonsSec3Content:
			"Édouard Dupont doesn't stop at just surveys! He had the stalagmites and stone boulders dynamited. Underneath these, he discovered some amazing things: remains of around twenty animal species, flint knives and arrows, coins from the Gallo-Roman period and various later utensils. On the other hand, there was not the slightest trace of human remains... However, the evidence suggested that the finding skulls was entirely possible!",
		nutonsSec4Title: "The %lpaleontologist%l listens to popular legends",
		nutonsSec4Content:
			"While Édouard Dupont chose this hole to begin his exploration, this was also because there is a legend associated with it... According to popular belief, the Nutons were dwarves with an old and wiry appearance, but active, very mischievous and very skilled with their hands. They lived hidden in rocks, caves and ruins. During the night, in return for a little food and a few treats left at the entrance to their lodgings, these supernatural beings repaired utensils, furniture, mended rags and washed the village women's linen. In the summer, they came out in large numbers to perform joyful dances in the meadows. While playful, they could also be very touchy and took revenge on those who tricked them or sought to discover their secrets.",
		nutonsEncart1Title: "A scientific explanation for the legend?",
		nutonsEncart1Content:
			"Édouard Dupont tries to explain the small size of the Furfooz populations: “The men of Grenelle, and especially those of Furfooz, were small in stature. The first lot found had an average height of 1m62, but the second lot dropped to 1m53. This is almost exactly the average height of the Lapps. However, this reduced stature did not rule out the vigour and agility necessary for wild populations. The bones of the limbs and trunk were robust, and the projections and depressions on their surfaces showed very pronounced muscular development. Apart from this general robustness, superior to what is usually encountered, the skeletons of the men of Furfooz and Grenelle strongly resemble those of today's people.\n\nÉdouard Dupont, “Sur les crânes de Furfooz”, Compte-rendu du Congrès de Préhistoire, 1872. pp. 251-252).",
		nutonsQuote1:
			"As mysterious as it is emblematic, the Nutons Hole always has something in store to surprise you with! Continue exploring the reserve to discover many more secrets.",

		/* Frontal */
		frontal: "Frontal Hole",
		frontalTitle: "Frontal Hole",
		frontalTitleDeter: "Frontal Hole",
		frontalSubtitle: "Between habitat and burial ground",
		frontalSec1Title:
			"The name Frontal Hole comes from a great discovery!",
		frontalSec1Subtitle:
			"Why do you think this is its name? Quite simply because our friend Édouard Dupont, while excavating the hole in 1864, found, with the first blows of the pickaxe, a frontal bone belonging to an adolescent. This great discovery was a remarkable event in the world of research; although others like it were to follow.",
		frontalSec2Title:
			"The discovery of two very different skulls: an enigma to be solved",
		frontalSec2Subtitle:
			"On December 10 of the same year, the paleontologist unearthed, in the presence of some distinguished guests, two intact human skulls preserved in an ossuary. This opens up a huge field of hypotheses regarding the period of occupation of these caves, but also the lifestyles of our ancestors.",
		frontalEncart1Title: "Dupont's account of the excavations",
		frontalEncart1Content:
			"“This small cavern, as much for its geological data and for the light it has shed on ancient ethnography, is the most important that has yet been excavated in our country. It is certain that if the explorer had as often seen their research crowned with such complete success, the laborious work undertaken in our region would have been greatly shortened, and there would remain today very little to reveal about the inhabitants of Belgium in the Quaternary period. [...] We will examine later the data that the remains of the meals in the outdoor shelter and the pile of human bones provide us with on the funeral customs of the ancient people.” Édouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		frontalSec3Title:
			"Making mistakes is part of the research!",
		frontalSec3Subtitle:
			"In his initial study of these bones, Dupont wrongly dated them to the time of the reindeer hunters. But being a paleontologist also means often being wrong! Further study of these skulls and of all the materials discovered at Frontal Hole reveals a confusion on Dupont's part between traces of Magdalenians, the famous hunters, and those of populations living in the Neolithic period.",
		frontalEncart2Title:
			"When Darwin's theory sheds light on the paleontological world",
		frontalEncart2Content:
			"The excavations undertaken by Dupont are considered to be the first anatomical argument supporting Darwin's theories, transcribed a few years before these discoveries, in 1859, in his work On The Origin of Species. Thanks to these skulls, Dupont understood the evolution of the human species through periods of occupation. Because yes, people from two eras lived at the Frontal Hole",
		frontalSec4Title: "People of two eras: life, death",
		frontalSec4Subtitle:
			"At the Frontal Hole, archaeological excavations have uncovered both cut flints dating from the Magdalenian period, and also numerous bones, from at least sixteen individuals, men, women and children, located well above the Magdalenian level layer. Everything suggests that these are the remains of a burial site dating from the Middle Neolithic period. Almost 10,000 years separate them and yet, they all chose the Frontal Hole as their final resting place!",
		frontalSec5Title: "Living and dying at Frontal Hole",
		frontalSec5Subtitle:
			"The bone pile suggests a burial site rather than a habitat. In fact, the burial process, that is to say the process of burying one's dead, extended to the entire territory of present-day Belgium during the Neolithic period, around 7,000 years ago. The Frontal Hole is a perfect example of this since it is even closed by a slab, as one would do for a tomb, which confirms that this is a secondary burial site dating from the Middle Neolithic.",
		frontalSec6Title:
			"From the Frontal Hole to the Jawbone hole… there are only 10 metres between them!",
		frontalSec6Subtitle:
			"Funeral practices were omnipresent at the Furfooz site. Just a few paces away from the Frontal Hole, the Jawbone Hole likely had the same function as five skeletons were discovered there, all in remarkable condition. And, as we know, studying death helps us to understand life!\n\nStopping at La Flobette, the refreshment bar located not far from the Frontal Hole, to fully enjoy the beauty of nature.",

		/* GoldenPaddle */
		goldenPaddle: "Golden Goat Cave",
		goldenPaddleTitle: "Golden Goat Cave",
		goldenPaddleTitleDeter: "Golden Goat Cave",
		goldenPaddleSubtitle:
			"A mine of information on the history of humanity!",
		goldenPaddleSec1Title:
			"Why on Earth would studying rocks help us learn about humans?",
		goldenPaddleSec1Subtitle:
			"“Studies of this nature, which draw their elements from the geological sciences, are therefore the exclusive domain of the natural sciences. Through these investigations, geology gives a hand to history and helps it to restore humanity’s past.”\n\nÉdouard Dupont, Prehistoric times in Belgium: Les temps préhistoriques en Belgique : l'Homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872\n\nThese few words from Édouard Dupont reflect the importance of studying rocks, sedimentation, and in understanding the history of humanity. Let us explain.",
		goldenPaddleSec2Title:
			"Sedimentation, a gold mine for scientists!",
		goldenPaddleSec2Subtitle:
			"By deciphering changes in the natural environment, people known as geomorphologists tell us about the human activities that shaped it. By studying the sediment, they determine both the origin of the deposits (alluvium, runoff, embankments, etc.) and their age. They also observe the succession of sediment layers because each layer corresponds to an era. They are visible thanks to changes in the colour or texture of the soil (clay, sand, pebbles, etc.). The stratigraphic method makes it possible to determine the nature and chronology of events. At the Golden Goat, the sedimentary layers are very visible and provide information on both human presence and the origins of Furfooz.",
		goldenPaddleSec3Title:
			"Excavations that cause damage can also happen…",
		goldenPaddleSec3Subtitle:
			"At the beginning of the 20th century, the site of Furfooz had not been inhabited for a long time but was a major tourist attraction, that visitors had to pay for. Unfortunately! After Dupont's extensive excavations, nothing was done to protect the interior of the caves. The influx of holidaymakers only made things worse with deterioration inside the caves, particularly due to the improper use of candles used for lighting.",
		goldenPaddleEncart1Title: "Legend has it…",
		goldenPaddleEncart1Content:
			"The legend of the country has it that a gatte, or a goat in Walloon, lives in the caves of Furfooz and guards a treasure... This popular story goes back to a distant time, when fabric for bags was scarce and banks did not exist. People made small bags out of hair-covered skins and hid their money wherever they could. So much so that sometimes they forgot about them or died with their secret... Those unfortunate souls with memory-loss made people happy much later! From this legend was born the famous golden goat!",
		goldenPaddleSec4Title:
			"Today, the Golden Goat is an unmissable natural location!",
		goldenPaddleSec4Subtitle:
			"Fortunately, the Ardenne & Gaume association is now working to preserve this natural heritage. Human presence has become almost invisible and gives way to an exceptional natural landscape. The view is breathtaking! Moreover, the Furfooz reserve is closed for part of the year to allow nature to regenerate. Nature needs a holiday too!",

		/* Reuviau */
		reuviau: "Reuviau Hole",
		reuviauTitle: "Reuviau Hole",
		reuviauTitleDeter: "Reuviau Hole",
		reuviauSubtitle: "At Reuviau Hole, it’s dinner time!",
		reuviauSec1Title: "Reuviau, a discreet hole but one rich in knowledge",
		reuviauSec1Subtitle:
			"Near the end of your walk through the Furfooz nature reserve, don't forget to stop at the Reuviau hole!  Modest in size, it is nevertheless very interesting for paleontologists, particularly to better understand the dietary practices of the Magdalenians.",
		reuviauEncart1Title: "Reuviau Excavations by Édouard Dupont",
		reuviauEncart1Content:
			"“Another small cave, the Reuviau hole, in a ravine on the path leading from the Lesse to Furfooz, offered, in the same geological circumstances, bones of horses, reindeer, bears, etc., broken by human hands.”\n\nÉdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec2Title: "What people ate in the Reindeer Age",
		reuviauSec2Subtitle:
			"Édouard Dupont, and later many other paleontologists, discovered a series of reindeer and horse bones. Everything suggests that they were a delicacy for these cavemen... to the point that we call it the \"Age of the Reindeer\" in their honour!Flints unearthed in the hole tell us a lot about how these people prepared the animals, both for food and clothing.",
		reuviauEncart2Title: "Dupont defines the Stone Age",
		reuviauEncart2Content:
			"“Hunting provided them with their food and their coarse clothing; bone and especially flint gave them tools and weapons, while still being unable to produce metals or even suspect their existence. These distant and long-lasting periods have therefore been called the Stone Ages.”\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec3Title: "Prehistoric hunting",
		reuviauSec3Subtitle:
			"During the Paleolithic, reindeer, passing in herds through the valleys, were therefore the game of choice for prehistoric people. Many traces of these hunts have been found in the various caves of Furfooz. A gregarious and migratory animal, the reindeer was undoubtedly easy game to hunt en masse… But also to dry out! All of these factors, studied by paleontologists, teach us yet more about our ancestors.",
		reuviauSec4Title: "The investigation into the Furfooz holes continues!",
		reuviauSec4Subtitle:
			"You have reached the end of your exploration but, have no doubt, you have only discovered a tiny part of the Furfooz nature reserve! Come back soon to explore its every nook and cranny, its lush flora and fauna, protected now for almost 70 years!\nSee you soon!",

		furfoozMystery: "The mysteries of Furfooz",
		archaeology: "Archaeology",
		doYouKnow: "Good to know",
		anecdote: "Anecdote",
		edouardDupont: "The Dupont anecdote",

		/* ************** */
		/* Glossary Datas */
		/* ************** */

		palaeontologist: "Paleontologist",
		palaeontologistDesc:
			"A person who studies the appearance, evolution and extinction of animal species over a very long period, dating back to the very first traces of life on Earth, around 3.5 billion years ago.",
		archaeologist: "Archaeologist",
		archaeologistDesc:
			"A person who studies past human civilisations based on the material traces left behind (buildings, works of art, coins, tombs, manuscripts, everyday objects, etc.)",
		geologist: "Geologist",
		geologistDesc:
			"A person who studies the composition and structure of soils and rocks in the Earth's crust to help explain the evolution of our planet.",
		anthropologist: "Anthropologist",
		anthropologistDesc:
			"A person who studies humans, their behaviour and their evolution. On archaeological sites they analyse human remains to determine their conditions of life and death. They also study burials (types of tomb, adornments, offerings, furniture) to be able to reconstruct any funeral ceremonies carried out by the relatives of the deceased.",
		sediment: "Sediment",
		sedimentDesc:
			"deposit that forms from the weathering or disintegration of ancient rocks.",
		experimentalArchaeology: "Experimental archaeology",
		experimentalArchaeologyDesc:
			"archaeological discipline involving the restoration, through experimentation, of buildings or objects using the construction and manufacturing techniques of the period.",
		necropolis: "Necropolis",
		necropolisDesc:
			"equivalent of a cemetery in Antiquity, revealed by archaeology. Graves may be located in underground excavations or excavated from the ground.",
		hypocaust: "Hypocaust",
		hypocaustDesc:
			"an underground room containing a system for heating baths, thermal rooms or even living areas.",
		darwinism: "Darwinism",
		darwinismDesc:
			"set of biological theories by Darwin and his disciples according to which natural selection is the essential factor in the transformation of living species.",
		prospecting: "Prospecting (archaeology)",
		prospectingDesc:
			"Prospecting consists of observing and studying an archaeological site without excavation. It can be done on foot (by picking up objects scattered over the surface), from an airplane (to see anomalies appear in relief) or even with radar devices that give an indication of potential buried buildings.",
		ossuary: "Ossuary",
		ossuaryDesc:
			"A pile of bones. It can also be a building where human bones are piled up.",

		palaeolithic: "Upper Paleolithic",
		palaeolithicDesc:
			"Third and final phase of the Paleolithic, this period is between -40,000 and -10,000 years. It is marked by the diversification of tools and the appearance of art. This is a period of major transition in the evolution of humans since after the disappearance of the Neanderthals around 30,000 BC, Homo sapiens became the only species representing the genus Homo. Its Greek etymology means \"the age of the old stone\", as opposed to \"the age of the new stone\", which designates the Neolithic which succeeded it.",
		magdalenian: "Magdalenian",
		magdalenianDesc:
			"Also called by Édouard Dupont “the age of the reindeer”, the Magdalenian is the most recent culture of the Upper Paleolithic. It developed in our regions between 15,000 and 10,000 years before our time, at the end of the last ice age. Here we see the rise of art and bone work.",
		neolithic: "Neolithic",
		neolithicDesc:
			"A period marking the end of prehistory, between 6,500 and 2,100 years before our time, when humans became sedentary and developed agriculture.",
		antiquity: "Antiquity",
		antiquityDesc:
			"The period from the invention of writing to the early Middle Ages, that is, the period between 3500 BC and 476 AD. (date of the fall of the Western Roman Empire)",
		lateRoman: "Late Empire",
		lateRomanDesc:
			"Period of the Roman Empire which extends from the end of the 3rd century to the fall of the Western Empire in 476. This period illustrates the transition between Antiquity and the Middle Ages.",
		highMiddleAges: "Early Middle Ages",
		highMiddleAgesDesc: "Period extending from the end of Antiquity to the end of the Carolingian period, or the end of the 10th century.",
		classicalMiddleAges: "High Middle Ages",
		classicalMiddleAgesDesc:
			"also called the Central Middle Ages, this period between the 11th and 13th centuries AD is characterised by a fragmentation of authority, the organisation of the feudal system and the development of castle architecture.",
	},
	{
		id: "de",
		flag: `url(${flagDe})`,
		lang: "Nederlands",
		/* Splashscreen */
		mainTitle: "Furfooz's Odyssey",
		mainTitleLine1: "Furfooz's",
		mainTitleLine2: "Odyssey",
		mainSubtitle: "Sur les traces de l'Homme",
		start: "Démarrer",
		/* Menu */
		timeline: "Frise",
		map: "Carte",
		glossary: "Lexique",
		/* Map */
		repereMap: "Repérez-vous sur la carte",
		enjoyExp:
			"Pour profiter à fond de l'expérience Furfooz, nous vous conseillons de partager votre localisation",
		letsgo: "C'est parti !",
		notNow: "Je ne suis pas sur place",
		ok: "Ok",
		dotInactiv1: "Ce point n'est pas encore actif !",
		dotInactiv2: "Vous devez d'abord déverouiller les points précédents.",
		restartExp: "Recommencer l'expérience ?",
		/* Credits */
		creditsTitle: "Furfooz's Odyssey",
		immersiveGame:
			"Un jeu immersif pour découvrir les trésors de Furfooz sur les traces d’Edmond, le célèbre archéologue belge.",
		manyMarquage:
			"Rendez-vous sur les différents marqueurs du parcours et trouvez sur place le code secret qui révélera des contenus exclusif et de nombreuses surprises !",
		playingInPlace: "Jouer en mode \"à distance\"",
		cutGeoloc: "Couper la géolocalisation et accéder aux contenus sans répondre aux énigmes",
		resetExp: "Réinitialiser l'expérience",
		credits: "Crédits",
		timescopeProject: "Un projet Timescope",
		creditsContent: "%bContenus%b Musée de l'homme de Bruxelles",
		creditsPhotos: "%bPhotographie%b Photographe",
		creditsTexts: "%bTextes %bOona Farell",
		creditsDesign: "%bDesign de l'application mobile%b Felix Marquette",
		/* SecretCode */
		validate: "Valider",
		validate2: "Ré-essayer",
		badanswer: "Ce n'est pas ça...",
		bravo: "Bravo !",
		continue: "Continuer",
		slideToDiscover: "Cliquer pour continuer",
		/* Poi */
		test: "Lorem ipsum...",
		legend: "Légende de l'image",
		backMap: "Retour à la carte",
		knowMoreWith: "En savoir plus sur ",
		backTime: "Remonter le temps",
		nowTime: "Retour au présent",
		temporalFilter: "Filtre temporel",
		/* Timeline */
		timelineTitle: "Frise Chronologique",
		scrollArrowsExpl:
			"Pssst... Si vous êtes fatigués de scroller, utilisez les flèches pour vous déplacer plus rapidement d'une période à l'autre !",
		explanationTimeline:
			"L'époque moderne ne représente qu'une petite fraction de l'histoire de l'humanité. Serez-vous capable de remontrer jusqu'aux traces des premiers humains à Furfooz dans cette frise où chaque pixel correspond à 1 an ?",
		timelineBottom: "Vous êtes arrivés au bout ! Bravo :)",
		timelineBackFutur: "Retour vers le futur",
		/* Glossary */
		explanationGlossaryLine1:
			"Voici les termes techniques que vous avez pu rencontrer jusqu'à présent.",
		explanationGlossaryLine2:
			"Découvrez d'autres points d'intérêts pour remplir votre lexique !",
		unlockTo: "Débloqué aux ",
		glossaryUnlock: "Nouveau terme ajouté au lexique !",
		goGlossary: "Voir le lexique",
		/* Cookies */
		cookiesTitle: "Ce site internet utilise des cookies",
		cookiesContent:
			"Nous utilisons des cookies pour sauvegardez vos progrès dans le jeu. En poursuivant l’expérience vous acceptez notre usage de cookies.",
		cookiesAccept: "Accepter",
		cookiesDenied: "Refuser",
		cookiesReadPrivacyPolicy: "Lire notre politique de confidentialité.",
		/* PrivatePolicy */
		privatePolicyTitle: "Politique de confidentialité",
		/* EndExp */
		welldone: "Beau travail !",
		allfound: "Vous avez trouvé tous les trésors de Furfooz.",
		allfound2: "Edouard Dupont serait fier de vous.",
		shareExp: "Partagez votre expérience",
		shareExpWithFriends: "Partagez votre expérience avec vos amis !",
		link: "Lien",
		x: "X",
		whatsapp:"Whatsapp",

		/* ********** */
		/* Quiz Datas */
		/* ********** */

		/* thermalBaths */
		thermalBathsRiddleDisc: "Vous avez résolu la première énigme !",
		thermalBathsRiddleTitle: "Pierre calcaire",
		thermalBathsRiddleDesc:
			"Fantastique ! En effet, les thermes sont bâtis à l’aide de pierres calcaires. Pour lier l’ouvrage, les Romains ont utilisé du mortier de teinte grisâtre compact et du fin gravier de rivière. Et puis, le plateau de Hauterecenne est fait de pierres calcaires, il n’y a plus qu’à se servir.",

		/* Hauterecenne */
		hauterecenneRiddleDisc: "Félicitations pour cette deuxième énigme !",
		hauterecenneRiddleTitle: "Pièce frappée",
		hauterecenneRiddleDesc:
			"Bien joué ! Sur le plateau de Hauterecenne, les archéologues ont découvert des pièces de monnaie en grande quantité… et traversant bien des siècles !",

		/* Nutons */
		nutonsRiddleDisc: "Encore une énigme réussie !",
		nutonsRiddleTitle: "Bois de renne gravé",
		nutonsRiddleDesc:
			"Bravo ! Au Trou des Nutons, on a découvert un bois de renne sur lequel est gravé une tête de bison, et lorsqu’on le retourne, on y voit un mammouth !",

		/* Frontal */
		frontalRiddleDisc: "Vous avez résolu la quatrième énigme !",
		frontalRiddleTitle: "Os frontal d’un adolescent",
		frontalRiddleDesc:
			"Félicitation ! Edouard Dupont a découvert en 1864 un os frontal appartenant à un adolescent ainsi que deux crânes humains intacts conservés dans un ossuaire.",

		/* GoldenPaddle */
		goldenPaddleRiddleDisc: "Encore une énigme, continuez ainsi !",
		goldenPaddleRiddleTitle: "Bout d’une roche avec des sédiments",
		goldenPaddleRiddleDesc:
			"Bravo ! Ce bout de roche est une source d’information précieuse pour les paléontologues. À l’intérieur, on y voit des sédiments, un dépôt qui se forme par la précipitation des matières en suspension ou dissoutes dans un liquide. Grâce à eux, nous pouvons dater le passage de l’Homme dans la grotte.",

		/* Reuviau */
		reuviauRiddleDisc: "C'était la dernière énigme !",
		reuviauRiddleTitle: "Silex taillé magdalénien",
		reuviauRiddleDesc:
			"Fantastique ! Vous êtes arrivés à la fin de votre parcours. Les silex taillés sont les outils emblématiques de la Préhistoire. Les cavités de Furfooz ont livré de belles séries de silex taillés magdaléniens et du Néolithique moyen.",

		/* ************** */
		/* Timeline Datas */
		/* ************** */

		/* Modern */
		timelineModern: "Epoque moderne",
		today: "Aujourd'hui",
		littleSticksOnSide:
			"Vous voyez ces petits traits sur le coté gauche de l'écran ? Chaque trait marque 10 ans sur cette frise chronologique qui remonte jusqu'aux toutes premières traces de l'homme.",
		/* Classical Middle Ages */
		timelineClassicalMiddleAges: "Moyen-Âge Classique",
		americaDiscover: "Découverte de l'amérique",
		romanFall: "Chute de l'empire romain",
		classicalMiddleAgesExpl:
			"Aussi appelée Moyen-Âge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
		classicalMiddleAgesStart: "Début du moyen age",
		classicalMiddleAgesEnd: "Fin du moyen age",
		/* Antiquity */
		timelineAntiquity: "Antiquité",
		antiquityDuration: "Période qui s’étend de l’invention de l’écriture au début du Moyen-Âge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		basEmpireExpl: "Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen-Âge.",
		timelineNeolithic: "Néolithique",
		neolithicDuration: "Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		/* Paleolithic */
		timelinePaleolithic: "Paléolithique",
		paleolithicDuration:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		timelineMagdalenian: "Magdalénien",
		magdalenianDuration:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		startPeriod: "start",
		endPeriod: "end",

		/* ********** */
		/* Pois Datas */
		/* ********** */

		/* parking */
		parking: "Parking",

		/* accueil */
		accueil: "Accueil",

		/* thermalBaths */
		thermalBaths: "Thermes gallo-romains",
		thermalBathsTitle: "Thermes gallo-romains",
		thermalBathsTitleDeter: "les Thermes gallo-romains",
		thermalBathsSubtitle:
			"Les thermes, un lieu incontournable de la vie à l’époque romaine/dans le quotidien des romains !",
		thermalBathsSec1Title: "Furfooz romain",
		thermalBathsSec1Content:
			"En toute terre où les romains s’installent, il est un lieu incontournable : les thermes. Furfooz ne déroge pas à la règle ! Et oui, au Bas-Empire, après la conquête romaine de l’Europe, le plateau de Hauterecenne devient un fortin très important occupé par des auxiliaires barbares au service de Rome. Il faut donc y construire des infrastructures…",
		thermalBathsSec2Title: "Comment ça fonctionne, des thermes ?",
		thermalBathsSec2Content:
			"D’une superficie modeste de 75m2, le bâtiment comprend tous les éléments traditionnels de ces établissements romains, à savoir : une salle au bain chaud (caldarium) et au bain tiède (tepidarium), ainsi qu’une salle au bain froid (frigidarium). La pièce principale est chauffée par %lhypocauste%l, tandis que le bain froid est situé à l’écart, en saillie sur une petite pièce quadrangulaire.",
		thermalBathsEncart1Title: "Pourquoi donc un établissement thermal ici ?",
		thermalBathsEncart1Content:
			"Il plane bien des mystères autour de la présence de thermes sur le site de Furfooz : De quelle période sont-ils datés? Comment les romains ont-ils réussi à acheminer l’eau jusqu’à l’édifice? Comment expliquer l’isolement total en cet endroit de cet ensemble thermal du Haut-Empire ? Antérieur au IVe siècle, certains auteurs justifient son existence par la présence possible d’une villa Gallo-romaine à proximité, mais aucune %lprospection%l n’a jamais pu en localiser l’emplacement. En ce qui concerne l’acheminement de l’eau, les hypothèses archéologiques vont bon train. En l’absence de sources sur le plateau, le géologue Van de Poel envisage par exemple l’existence d’une conduite d’eau qui aurait alimenté les thermes au départ d’une source située à quelques 600m delà, au Fond-des-Vaux. Les %larchéologues%l ont encore de nombreuses questions à élucider !",
		thermalBathsSec3Title: "Plus qu’un bain, un lieu social et civique !",
		thermalBathsSec3Content:
			"Si les thermes ont pour fonction première d’assurer l’hygiène de la cité, ces bains publics sont aussi un haut lieu de sociabilité. Dans la société romaine, on y va tous les jours pour y rencontrer ses amis ou encore y faire du sport, et ce qu’importe son statut social ! Ils sont ouverts à tous, sans distinction de classe sociale, aux hommes comme aux femmes (dans des parties ou à des heures différentes).",
		thermalBathsEncart2Title:
			"L’archéologie expérimentale, une aventure scientifique !",
		thermalBathsEncart2Content:
			"Ils sont passionnés ces archéologues ! D’abord exploré en 1876-1877 par Alfred Bequet de la Société archéologique de Namur, il faut attendre le XXe siècle, de 1956 à 1958, pour qu’on entreprenne une reconstruction fidèle et grandeur nature des thermes romains. Grande aventure scientifique, l’archéologie expérimentale permet de reconstituer ces édifices disparus, de la façon la plus rigoureuse possible, et ainsi rendre accessible la pratique et l’Histoire à un large public. Un bel exemple de pédagogie !",
		thermalBathsSec4Title:
			"Quand l’horizon du Moyen- ge sonne le glas des thermes gallo-romains",
		thermalBathsSec4Content:
			"Si les thermes sont parfaitement visibles grâce à leurs reconstructions, l’histoire du site est bien plus complexe. En effet, à l’orée du Moyen- ge, le plateau de Hauterecenne est progressivement abandonné par le camp romain. Vers le dernier tiers du IVe siècle, le site des thermes va alors prendre une nouvelle fonction, celle d’une petite %lnécropole%l, une fois le bâtiment détruit.",
		thermalBathsEncart3Title:
			"Bâtir une nécropole sur les ruines de thermes gallo-romain",
		thermalBathsEncart3Content:
			"Pour enterrer ses morts, la communauté occupant le site fortifié à la fin du IVe siècle jette son dévolu sur l’espace thermal, devenu terrain vague. La surface nécessaire pour y enfouir les défunts n’est pas très grande puisqu’on compte environ vingt-cinq sépultures fouillées. Les tombes sont creusées à même le sol, sans fondation ou infrastructure. L’étude de cette nécropole tardive dit beaucoup de choses de ses habitants, notamment la découverte de nombreux objets. Car oui, les haches laissent penser que la population de Furfooz seraient de nouveaux arrivants barbares, mais les beaux ceinturons enterrés appartiennent plus à la culture militaire romaine tardive. D’autres trouvailles compliquent l’identification certaine de ce peuple. Quoi qu’il en soit, c’est un beau mélange de culture à Furfooz !",
		thermalBathsSec5Title:
			"Actualité de l’archéologie, le site à nouveau fouillé !",
		thermalBathsSec5Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Hauterecenne */
		hauterecenne: "Plateau fortifié de Hauterecenne",
		hauterecenneTitle: "Plateau fortifié de Hauterecenne",
		hauterecenneTitleDeter: "le Plateau fortifié de Hauterecenne",
		hauterecenneSubtitle: "Une forteresse, plusieurs vies !",
		hauterecenneQuote1:
			"“L’homme, à toutes les époques, s’est établi dans cette localité [Furfooz] suivant les diverses conditions de son temps.”",
		hauterecenneQuoteAuthor1:
			"Edouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		hauterecenneSec1Title:
			"Les traces de fortifications scrutées à la loupe tardivement",
		hauterecenneSec1Content:
			"Le champ de recherche préféré à Furfooz, c’est la paléontologie. Et pourtant, il existe d’autres traces de la présence humaine bien plus tardives et qui méritent une pleine attention ! Le plateau de Hauterecenne, au cœur de cette activité humaine du %lBas-Empire%l et du Haut Moyen Âge, est fouillé marginalement au XIXe siècle, mais il faut attendre les années 1930, puis 1980 pour qu’il fasse l’objet d’études approfondies.\nEn route pour découvrir tous ses secrets !",
		hauterecenneSec2Title:
			"Hauterecenne, un plateau stratégique pour l’installation… d’une garnison romaine !",
		hauterecenneSec2Content:
			"En étudiant les traces des anciens remparts, les archéologues ont mis au jour la présence d’une garnison romaine du Bas-Empire sur le site de Furfooz. Mais pourquoi s’installer ici ? Pour sa situation stratégique bien-sûr ! Le plateau de Hauterecenne est un haut promontoire dominant la rivière, qui dispose d’une protection naturelle de ce côté mais aussi sur son flanc où le Ry des Vaux a lui-même creusé une petite vallée escarpée. Ainsi, situé au-dessus d’une falaise abrupte surplombant la Lesse de 60 mètres, il est très facile de fortifier l site et de se parer contre toute attaque ennemie ! Le plateau porte encore les traces bien conservées d’un certain nombre de structures anciennes et d’éléments de fortifications de périodes différentes. Les archéologues vous racontent.",
		hauterecenneEncart1Title:
			"Les archéologues étudient ces lieux stratégiques",
		hauterecenneEncart1Content:
			"« Depuis le milieu du XIXème siècle, on y a conduit plusieurs campagnes de fouilles et le grand mérite de Raymond Brulet est d’avoir repris l’étude du site [...]. Le site a été ainsi occupé depuis l’époque romaine jusqu’au XIIIème siècle avec des temps forts au milieu du IIIème siècle et au début du Vème siècle. » Raymond Brulet, La fortification de Hauterecenne à Furfooz, (publication d'histoire de l'Art et d'Archéologie de l'Université catholique de Louvain XIII), 1978",
		hauterecenneSec3Title: "Puis d’une petite forteresse médiévale !",
		hauterecenneSec3Content:
			"Raymond Brulet nous met sur la piste… La forteresse aurait donc évolué au fil du temps ? Et oui, après la chute de l’Empire romain et le départ de ses troupes, le site se fortifie épisodiquement. Nous n’avons que peu d’informations quant aux habitants qui s’y installèrent, en revanche la présence d’une maison forte et l’ajout de remparts suggèrent l’installation d’un petit seigneur régional. À la fin du XIIIème siècle, le site perd de son importance stratégique et est progressivement abandonné par l’Homme.",
		hauterecenneEncart2Title: "Savez-vous ce qu’est une maison forte ?",
		hauterecenneEncart2Content:
			"La maison forte est l’ancêtre des châteaux forts. De taille modeste, il n’est possible d’y pénétrer que par le 1er étage. Ainsi, si la maison est attaquée, l’escalier peut être retiré et les occupants protégés !",
		hauterecenneSec4Title:
			"Une superposition d’époques, comment faire pour les distinguer ?",
		hauterecenneSec4Content:
			"Pour retracer l’histoire et l’évolution de l’occupation humaine sur un site, l’archéologue outillé, mais s’entoure de spécialistes. Dans le cas du plateau fortifié, il faut pouvoir distinguer les époques, alors qu’elles se superposent. On travaille alors sur une chronologie relative et on fait appel à des comparaisons site par site. Ce long cheminement a permis de faire une découverte importante : la présence de deux murs de barrage.",
		hauterecenneSec5Title: "L’affaire des deux murs de barrage",
		hauterecenneSec5Content:
			"Et oui, les différentes époques sont visibles sur les murs ! Le premier mur de barrage, le plus extérieur, date du début du Bas-Empire romain. Très étroit et construit selon la même technique que les thermes, cela laisse à penser qu’il devait être muni soit d’un talus de terre appuyé contre lui, soit d’un parapet en bois, ancré dans le mur. Pour une raison que l’on ignore, un second mur de barrage a été élevé ensuite. Il bénéficie d’une large porte charretière et son épaisseur est cette fois plus commune. Il remonte sans doute au Ve siècle. Lors de la dernière mise en défense du site, à l’époque du Moyen- ge classique, ce mur a été bouché, puis surélevé. Son système de circulation au sommet de l’infrastructure repose sur un série de piliers à arcatures.",
		hauterecenneSec6Title: "Et si on fouillait encore ce lieu ?",
		hauterecenneSec6Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Nutons */
		nutons: "Trou des Nutons",
		nutonsTitle: "Trou des Nutons",
		nutonsTitleDeter: "le Trou des Nutons",
		nutonsSubtitle: "Entre découvertes paléontologiques et légende populaire",
		nutonsSec1Title: "Le Trou des nutons, première grotte explorée",
		nutonsSec1Content:
			"En 1864, notre cher Edouard Dupont se lance en premier dans la fouille du trou des Nutons. S’il le choisit comme point de départ de son exploration, c’est parce qu’il présente toutes les caractéristiques idéales à l’implantation d’un campement magdalénien : bien abrité, logé à 40 m au-dessus du lit de la rivière donc proche d’une source d’eau… parfait pour cette population semi-nomade de chasseurs de rennes ou de chevaux !",
		nutonsSec2Title: "Quand l’étude des sols rencontre l’étude des Hommes",
		nutonsSec2Content:
			"Mais comment diable Edouard Dupont tire-t-il autant de conclusions au sujet d’une population si lointaine ? En effectuant ce l’on appelle en archéologie un sondage en archéologie. Sur une toute petite partie de la grotte, l'équipe de Dupont retire les couches successives du sol, jusqu’à 8 m de profondeur pour les étudier. Ces couches sont composées de sédiments, c’est-à-dire de dépôts organiques qui en disent beaucoup sur la période d’occupation et les modes de vie de nos ancêtres. Indéniablement, géologie et archéologie sont intimement liées.",
		nutonsSec3Title: "Des premières grandes découvertes",
		nutonsSec3Content:
			"Edouard Dupont ne s’arrête pas aux sondages ! Il fait dynamiter les stalagmites et les blocs de pierre. Sous ceux-ci, il fait de grandes découvertes : des débris  d’une vingtaine d’espèces animales, des couteaux et des flèches en silex, des pièces de monnaie de l’époque gallo-romaine et divers ustensiles postérieurs. Par contre, il n’y a pas la moindre trace d’ossements humains… Pourtant, tout portait à croire que la découverte de crânes était possible !",
		nutonsSec4Title: "Le %lpaléontologue%l écoute les légendes populaires",
		nutonsSec4Content:
			"Et oui ! Si Edouard Dupont fait le choix de ce trou pour commencer son exploration, c’est aussi car une légende lui est associée… Selon la croyance populaire, les Nutons étaient des nains à l’aspect vieux et chétif, mais actifs, très malicieux et fort habiles de leurs mains. Ils vivaient cachés dans les rochers, les cavernes et les ruines. Pendant la nuit, pour un peu de nourriture et quelques friandises déposées à l’entrée de leurs  gîtes, ces êtres surnaturels réparaient les ustensiles, les objets mobiliers, raccommodaient des haillons et blanchissaient le linge des villageoises. À la belle saison, ils sortaient en nombre pour se livrer à de joyeuses danses dans les prés. Taquins, ils pouvaient être très susceptibles et se vengeaient de ceux qui les bernaient ou cherchaient à découvrir leurs secrets.",
		nutonsEncart1Title: "Une explication scientifique de la légende ?",
		nutonsEncart1Content:
			"Edouard Dupont tente d’expliquer la petite taille des populations de Furfooz : « Les hommes de Grenelle, et surtout ceux de Furfooz, étaient de petite taille. Les premiers alignaient encore une moyenne d’1m62, mais les seconds descendaient à 1m53. C’est presque exactement la taille moyenne des Lapons. Toutefois, cette stature réduite n’excluait ni la vigueur ni l’agilité nécessaire aux populations sauvages. Les os des membres et du tronc sont robustes, et les saillies, les dépressions de leur surface, accusent un développement musculaire très prononcé. À part cette robusticité générale, supérieure à ce que l’on rencontre habituellement, les squelettes des hommes de Furfooz et de Grenelle ressemblent fort à celui des hommes d’aujourd’hui » (Edouard Dupont, « Sur les crânes de Furfooz », Compte-rendu du Congrès de Préhistoire, 1872. pp. 251-252).",
		nutonsQuote1:
			"Aussi mystérieux qu’emblématique, le Trou des Nutons a toujours de quoi vous surprendre ! Continuez votre exploration de la réserve pour découvrir bien d’autres secrets.",

		/* Frontal */
		frontal: "Trou du Frontal",
		frontalTitle: "Trou du Frontal",
		frontalTitleDeter: "le Trou du Frontal",
		frontalSubtitle: "Entre habitat et sépulture",
		frontalSec1Title:
			"S’il est appelé Trou du Frontal, c’est grâce à une découverte !",
		frontalSec1Subtitle:
			"À votre avis, pourquoi ce nom ? Tout simplement car notre ami Edouard Dupont, en fouillant le trou en 1864, met la main dès les premiers coups de pioche sur un os frontal appartenant à un adolescent. Cette grande découverte est un événement dans le monde de la recherche et est annonciatrice de bien d’autres…",
		frontalSec2Title:
			"la découverte de deux crânes très différents, une énigme à élucider",
		frontalSec2Subtitle:
			"Le 10 décembre de la même année, le paléontologue dégage, en présence d’invités de marque, deux crânes humains intacts conservés dans un ossuaire. Cela ouvre un champ d’hypothèses immense quant à la période d’occupation de ces grottes, mais aussi aux modes de vie de nos ancêtres.",
		frontalEncart1Title: "Récit de fouilles par Dupont",
		frontalEncart1Content:
			"“Cette petite caverne, tant par ses données géologiques que par les lumières qu’elle a répandues sur l’ethnographie ancienne, est la plus importante qui ait encore été fouillée dans notre pays. Il est certain que si l’explorateur eût souvent vu ses recherches couronnées d’un succès aussi complet, les laborieux travaux, entrepris dans notre région, eussent été fort abrégés, et il resterait aujourd’hui bien peu de chose à dévoiler sur les habitants de la Belgique à l’époque quaternaire. [...] Nous examinerons plus loin les données que les restes des repas dans l’abri extérieur et l’amas d’ossements humains nous fournissent sur les coutumes funéraires de l’antique peuplade.” \n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		frontalSec3Title:
			"Se tromper fait partie de la recherche paléontologique !",
		frontalSec3Subtitle:
			"En étudiant ces ossements, Dupont les date à tort de l’époque des chasseurs de rennes. Mais être paléontologue c’est aussi se tromper ! Des études plus approfondies sur ces crânes et de l’ensemble des matériaux découverts sur le site ont mis au jour une confusion de la part de Dupont entre des traces de Magdaléniens, les fameux chasseurs, et celles de populations vivant au Néolithique.",
		frontalEncart2Title:
			" Quand la théorie de Darwin éclaire le monde paléontologique",
		frontalEncart2Content:
			"Les fouilles entreprises par Dupont sont considérées comme le premier argument anatomique en faveur des théories de  Darwin, retranscrites dans son ouvrage L’origine des espèces publié en 1859, quelques années avant ses découvertes. Grâce à ces crânes, il comprend l’évolution de l’espèce humaine en fonction des périodes d’occupation. Car oui, des Hommes de deux ères vivaient au trou du Frontal !",
		frontalSec4Title: "Des Hommes de deux ères : la vie, la mort",
		frontalSec4Subtitle:
			"Au Trou du Frontal, les fouilles archéologiques ont mis au jour à la fois des silex taillés datant de l’époque %lMagdalénienne%l, mais aussi de nombreux ossements, d’au moins 16 individus (hommes, femmes et enfants) situés nettement au-dessus de la couche du niveau magdalénien. Tout porte à croire qu’il s’agit-là de vestiges d’une sépulture datant du Néolithique moyen. Près de 10 000 ans les séparent et pourtant, tous ont choisi le trou du Frontal pour refuge !",
		frontalSec5Title: "Vivre et mourir au Trou du Frontal",
		frontalSec5Subtitle:
			"L’amas d’ossements suggère un espace funéraire plutôt qu’un habitat. En effet, le procédé d’inhumation, c’est-à-dire d’enterrer ses morts s’étend à tout le territoire actuel de la Belgique pendant le Néolithique, il y a 7000 ans. Le Trou du Frontal en est un parfait exemple puisqu’il est même fermé par une dalle, comme on le ferait pour une tombe, ce qui confirme qu’il s’agit-là d’une sépulture secondaire datant du Néolithique moyen.",
		frontalSec6Title:
			"Du trou du Frontal au trou de la Mâchoire… il n’y a que 10 mètres à parcourir !",
		frontalSec6Subtitle:
			"La pratique funéraire est très présente sur le site de Furfooz. À quelques pas de trou du Frontal, le trou de la Mâchoire avait sans doute la même fonction puisqu’on y a découvert cinq squelettes dans un état remarquable. Et, nous le savons, étudier les morts c’est comprendre la vie !\nPassez à la buvette située non loin du trou du Frontal pour profiter pleinement des beautés de la nature.",

		/* GoldenPaddle */
		goldenPaddle: "Grotte de la Gatte d'Or",
		goldenPaddleTitle: "Grotte de la Gatte d’Or",
		goldenPaddleTitleDeter: "la Grotte de la Gatte d’Or",
		goldenPaddleSubtitle:
			"Une mine d’informations sur l’histoire de l’humanité !",
		goldenPaddleSec1Title:
			"Pourquoi diable étudier la roche pour en apprendre sur les Hommes ?",
		goldenPaddleSec1Subtitle:
			"“Les études de cette nature, qui puisent leurs éléments dans les sciences géologiques, sont donc du ressort exclusif des sciences naturelles. Par ces investigations, la géologie donne la main à l’histoire et l’aide à restaurer le passé le l’humanité.” Edouard Dupont, Les temps préhistoriques en Belgique : l'Homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse,1872\n\nCes quelques mots de Edouard Dupont transmettent bien l’importance de l’étude de la roche, la sédimentation, dans la compréhension de l’histoire de l’humanité. On vous explique.",
		goldenPaddleSec2Title:
			"La sédimentation, une mine d’or pour les scientifiques !",
		goldenPaddleSec2Subtitle:
			"En décryptant les modifications du milieu naturel, celui que l’on appelle le géomorphologue nous renseigne sur les activités humaines qui l’ont façonné. En étudiant les sédiments, il détermine l’origine des dépôts (alluvions, ruissellements, remblais…) ou leur âge. Il observe aussi la succession des strates de sédiments visible grâce aux changements de couleur ou de texture des sols (argiles, sables, cailloux…). Chaque strate correspondant à une époque, la méthode stratigraphique permet de déterminer la nature et la chronologie des événements intervenus. À la Gatte d’Or, les couches sédimentaires sont très visibles et renseignent aussi bien sur la présence humaine que sur les origines même de Furfooz.",
		goldenPaddleSec3Title:
			"Des fouilles qui détériorent le patrimoine, cela arrive aussi…",
		goldenPaddleSec3Subtitle:
			"Au début du XXe siècle, le site de Furfooz n’est plus habité depuis longtemps mais est un haut lieu touristique payant. Hélas ! Après les nombreuses fouilles de Dupont, rien n’a été prévu pour protéger l’intérieur des grottes. L’afflux des vacanciers n’a fait qu’empirer les choses avec une détérioration à l’intérieur des  grottes dont celle de la Gatte d’or : bris de concrétions calcaires et altération de la teinte des  draperies suite à un mauvais emploi des bougies utilisées pour l’éclairage.",
		goldenPaddleEncart1Title: "La légende raconte…",
		goldenPaddleEncart1Content:
			"La légende du pays raconte qu’une gatte, une chèvre en wallon, vivrait dans les grottes de Furfooz et garderait un trésor… Cette histoire populaire remonte à une lointaine époque, où le tissu manquait et les banques n’existaient pas. Les gens confectionnaient de petits sacs en peau poilue et cachaient leurs deniers où ils pouvaient. Si bien que parfois, ils les oubliaient ou mourraient avec leur secret… Les malheureux sans mémoire font des heureux bien plus tard ! De cette légende naît la fameuse gatte toute d’or vêtue !",
		goldenPaddleSec4Title:
			"Aujourd’hui, la Gatte d’or est un spot naturel incontournable !",
		goldenPaddleSec4Subtitle:
			"Heureusement, l’association Ardenne & Gaume œuvre aujourd’hui pour la préservation de ce patrimoine. La présence humaine se fait quasi invisible et laisse place à un paysage naturel exceptionnel. La vue y est à couper le souffle !",

		/* Reuviau */
		reuviau: "Trou Reuviau",
		reuviauTitle: "Trou Reuviau",
		reuviauTitleDeter: "le Trou Reuviau",
		reuviauSubtitle: "Au Trou Reuviau, on se met à table !",
		reuviauSec1Title: "Reuviau, un trou discret mais riche en savoir",
		reuviauSec1Subtitle:
			"À la fin de votre promenade dans la réserve naturelle de Furfooz, n’oubliez pas de vous arrêter au trou Reuviau !  Modeste, il est pourtant très intéressant pour les paléontologues, notamment pour connaître mieux les pratiques alimentaires des Magdaléniens. On vous explique.",
		reuviauEncart1Title: "Fouilles du Reuviau par E.Dupont",
		reuviauEncart1Content:
			"“Une autre petite caverne, le trou Reuviau, dans un ravin sur le chemin qui conduit de la Lesse à Furfooz, a fourni, dans les mêmes circonstances géologiques, des ossements de cheval, de renne, d’ours, etc., brisés de main d'homme.”\n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec2Title: "Le repas des Hommes à l’âge du renne",
		reuviauSec2Subtitle:
			"Edouard Dupont, puis bien d’autres paléontologues, ont découvert une série d’ossement de rennes et de chevaux qui portent à croire qu’ils étaient des mets de choix pour ces hommes des cavernes… cela a même donné le nom à l’âge du renne, pour dire ! Des silex mis au jour dans le trou en disent beaucoup sur la façon dont ces hommes dépeçaient les bêtes, aussi bien pour se nourrir que pour se vêtir.",
		reuviauEncart2Title: "Dupont défini l’âge de pierre",
		reuviauEncart2Content:
			"“La chasse leur fournissait leurs aliments et leurs grossiers vêtements ; l’os et surtout la pierre-à-feu leur donnaient des outils et des armes, sans qu’elles eussent pu arriver à produire des métaux ou seulement à en soupçonner l’existence. Aussi a-t-on désigné, sous le nom d’âges de pierre, ces époques lointaines et de longue durée.”\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec3Title: "La chasse à la Préhistoire",
		reuviauSec3Subtitle:
			"Durant le paléolithique, le renne, passant en troupeaux dans les vallées, est le gibier de prédilection des hommes préhistoriques. De nombreuses traces de ces chasses ont été retrouvées dans les diverses cavernes de Furfooz. Animal grégaire et migrateur, le renne était sans doute aussi un animal facile à chasser, et à chasser en masse… Mais aussi à faire sécher ! L'ensemble de ces facteurs, étudiés par les paléontologues, nous en apprennent toujours un peu plus sur nos ancêtres !",
		reuviauSec4Title: "L’enquête dans les trous de Furfooz continue !",
		reuviauSec4Subtitle:
			"Vous arrivez à la fin de votre exploration mais, n’en doutez pas, vous n’avez découvert qu’une infime partie de la réserve naturelle de Furfooz ! N’hésitez pas à revenir pour en explorer ses moindres recoins, sa faune et sa flore luxuriantes, protégées depuis près de 70 ans !\nÀ bientôt.",

		furfoozMystery: "Les mystères de Furfooz",
		archaeology: "Archéologie",
		doYouKnow: "Bon à savoir",
		anecdote: "Anecdote",
		edouardDupont: "L'anecdote Dupont",

		/* ************** */
		/* Glossary Datas */
		/* ************** */

		palaeontologist: "Paléontologue",
		palaeontologistDesc:
			"celui qui étudie l'apparition, l'évolution et l'extinction des espèces animales, au long d'une immense période qui remonte aux premières traces de vie sur Terre, il y a environ 3,5 milliards d'années.",
		archaeologist: "Archéologue",
		archaeologistDesc:
			"celui qui étudie les civilisations humaines du passé à partir de traces matérielles qui en subsistent (bâtiments, œuvres d’art, pièces de monnaie, tombes, manuscrits, objets de la vie quotidienne…).",
		geologist: "Géologue",
		geologistDesc:
			"celui qui étudie la composition et la structure des sols et roches de la croûte terrestre. Le but? Expliquer l’évolution de notre planète.",
		anthropologist: "Anthropologue",
		anthropologistDesc:
			"celui qui étudie l’homme, son comportement et son évolution. Sur les chantiers archéologiques, il analyse les squelettes afin de déterminer les conditions de vie et de mort de la personne. Il décrit également les sépultures (type de tombe, parures, offrandes, mobilier) afin de pouvoir reconstituer les gestes funéraires effectués par les proches du défunt.",
		sediment: "Sédiment",
		sedimentDesc:
			"dépôt qui se forme à partir de l’altération ou de la désagrégation de roches anciennes.",
		experimentalArchaeology: "Archéologie expérimentale",
		experimentalArchaeologyDesc:
			"Discipline de l’archéologie, cette discipline consiste à restituer, grâce à l’expérimentation, des bâtiments ou objets selon les techniques de construction et de fabrication d’époque.",
		necropolis: "Nécropole",
		necropolisDesc:
			"Equivalent d’un cimetière à l’Antiquité, révélé par l’archéologie. Les sépultures peuvent être situées dans des excavations souterraines ou être creusées dans le sol.",
		hypocaust: "Hypocauste",
		hypocaustDesc:
			"Local souterrain renfermant un système destiné à chauffer les bains, les salles des thermes ou encore des pièces à vivre.",
		darwinism: "Darwinisme",
		darwinismDesc:
			"Ensemble des théories biologiques de Darwin et de ses disciples selon lesquels la sélection naturelle est le facteur essentiel de la transformation des espèces vivantes.",
		prospecting: "Prospection (archéologie)",
		prospectingDesc:
			"La prospection consiste à observer et étudier un site archéologique sans creuser dans le sol. Elle peut se faire à pied (en ramassant des objets éparpillés à la surface), depuis un avion (pour voir apparaître des anomalies dans le relief) ou encore avec des appareils radars qui donneront une indication sur d'éventuels bâtiments enfouis.",
		ossuary: "Ossuaire",
		ossuaryDesc:
			"Amas d’ossements. Cela peut aussi être un bâtiment où les ossements humains sont entassés.",

		palaeolithic: "Paléolithique supérieur",
		palaeolithicDesc:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		magdalenian: "Magdalénien",
		magdalenianDesc:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		neolithic: "Néolithique",
		neolithicDesc:
			"Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		antiquity: "Antiquité",
		antiquityDesc:
			"Période qui s’étend de l’invention de l’écriture au début du Moyen  ge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		lateRoman: "Bas-Empire",
		lateRomanDesc:
			"Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen-Âge.",
		highMiddleAges: "Haut-Moyen-Âge",
		highMiddleAgesDesc: "Période qui s’étend de la fin de l’Antiquité à la fin de la période carolingienne, soit à la fin du Xe siècle.",
		classicalMiddleAges: "Moyen-Âge classique",
		classicalMiddleAgesDesc:
			"aussi appelée Moyen- ge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
	},
];
